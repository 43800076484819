.m1ab_header + .standard-module.overlappable {

  //background: transparent;
  @include media-breakpoint-up(md) {
    margin-top: -20px;
  }
  @include media-breakpoint-up(md) {
    margin-top: -40px;
  }

  //> .container {
  //  padding-top: 50px;
  //  background: $white;
  //  @include media-breakpoint-up(lg) {
  //    padding-top: 110px;
  //  }
  //}
}

.no-bg {
  background: transparent !important;
}

.standard-module.bg-contained {
  padding: 0;
  @include media-breakpoint-up(md) {
    background: transparent;
    padding: 0px 65px 0px;

  }

  > .container {
    padding-top: 50px;
    padding-bottom: 50px;
    background: $white;
    //@include media-breakpoint-up(lg) {
    //  padding-top: 110px;
    //}
  }
}

.standard-module {
  padding-top: 70px;
  padding-bottom: 50px;
  background: $white;


  @include media-breakpoint-up(md) {
    //padding-top: 60px;
    //padding-left: 65px;
    //padding-right: 65px;
    //padding-bottom: 70px;
    padding: 60px 65px 70px;

  }

  &.article-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.ma21c_article-header:not(.no-hero) + .article-wrapper {

  margin-top: -245px;
  padding-top: 245px;
  background: $white;

  @include media-breakpoint-up(md) {
    background: transparent;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
    padding-top: 40px;
  }
}


.module-header {
  padding-bottom: 40px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-end;
    border: 0;


    .col-md-6, .col-md-5, .col-md-7 {
      align-items: flex-end;
      display: flex;
    }
  }

  h1 {
    font-family: saintecolombe;
    font-size: 36px;
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 60px;
      text-align: left;
      margin: 0 0 0 -5px;

      display:flex;
      align-items: center;
      max-width: 80%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 90px;
    }
  }


  p {
    font-family: interstate;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-weight: 100;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: 20px;
      text-align: left;
      letter-spacing: -.23px;
      line-height: 32px;
      margin-bottom: -3px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 3px;
    }
  }

  .learn-more {
    margin-top: -10px;
    margin-bottom: 10px;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

}


.module-header.narrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 24px;
    max-width: 325px;
    line-height: 1.5em;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 45px;
      max-width: 600px;
      line-height: 55px;
      padding-bottom: 20px;
    }
  }

  p {
    max-width: 80%;
    line-height: 26px;
    font-weight: 100 !important;
    text-align: center;
    @include media-breakpoint-up(lg) {
      font-size: 20px;
      line-height: 33px;
      max-width: 550px;
      padding-bottom: 28px;
    }
  }

  a.module-header-bottom-link {
    text-align: center;

  }

  &.larger {
    h1 {
      @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 40px;
        padding-bottom: 0px;
      }
    }
  }
}


.card-wrapper {
  @include media-breakpoint-up(md) {
    &:hover {
      .card-container {
        transform: translateY(-20px);
        transition: all 0.2s ease-out;

        .learn-more {
          opacity: 100;
        }
      }
    }
  }
}

.vert-center-flex {
  display: flex;
  align-items: center;
}

.card-container {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
  transition: all 0.2s ease-out;

  @include media-breakpoint-up(md) {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .image-container {
    height: 260px;
    width: 100%;
    margin-bottom: 15px;


    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
      height: 240px;
      margin-left: -8px;
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain;';
      @include media-breakpoint-up(md) {
        width: calc(100% - 10px);
      }
    }
  }

  .title {
    font-family: saintecolombe;
    font-size: 20px;
    line-height: 24px;
    font-weight: 100;
    margin-bottom: 20px;
    width: 100%;
    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
    }
  }

  .body {
    font-family: interstate;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  .learn-more {
    opacity: 100;

    @include media-breakpoint-up(md) {
      opacity: 0;
    }
  }

  .label {
    margin-bottom: 22px;
  }

}


.learn-more {
  margin-top: 10px;
  color: $citiblue;
  font-size: 16px;
  font-weight: 100;
  cursor: pointer;

  .arrow {
    margin-left: 6px;
    position: relative;
    top: -2px;
    width: 13px;
    $trans: all 0.2s ease-out;
    -webkit-transition: $trans;
    -moz-transition: $trans;
    -ms-transition: $trans;
    -o-transition: $trans;
    transition: $trans;
  }

  &:hover {
    color: $citiblue;

    .arrow {
      transform: translateX(8px);
    }
  }
}

.label {
  text-transform: uppercase;
  background: $aqua;
  min-height: 28px;
  font-size: 14px;
  letter-spacing: -.23px;
  color: $black;
  line-height: unset;
  font-weight: 100;
  padding: 4px 12px 0px;
}


.owl-nav {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  justify-content: space-between;

  button {
    border: none;
    background: none;
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }


  .owl-prev {
    margin-left: 20px;
  }

  .owl-next {
    margin-right: 20px;
  }

  .owl-prev, .owl-next {
    &:focus {
      outline: 0;
    }

    &.disabled {
      .owl-left-arrow, .owl-right-arrow {
        background-image: url("/img/icons/carousel-inactive-arrow.svg");
      }

      .owl-left-arrow {
        transform: rotate(0);
      }

      .owl-right-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .owl-left-arrow, .owl-right-arrow {
    background-image: url("/img/icons/carousel-arrow.svg");
    background-size: 100%;
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
  }

  .owl-left-arrow {
    transform: rotate(180deg);
  }

  .owl-right-arrow {
  }
}

.owl-dots {
  text-align: center;
}

.owl-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: black !important;
  display: inline-block;
  margin: 0px 4.5px;

  opacity: 0.15;
  $trans: opacity 0.2s ease-out;
  -webkit-transition: $trans;
  -moz-transition: $trans;
  -ms-transition: $trans;
  -o-transition: $trans;
  transition: $trans;

  &.active {
    opacity: 1;
  }
}


.person {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .headshot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .headshot {
    height: 150px;
    width: 150px;
    background: $ltgrey;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain;';
      mix-blend-mode: multiply;
    }
  }

  .description {

  }

  .text-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 25px;
    padding-right: 20px;

    @include media-breakpoint-up(md) {
      width: 100%;


    }

    .name {
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 7px;
    }

    .role, .location {
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 100;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 200px;
      }
    }
  }

  .description {
    font-size: 16px;
    line-height: 26px;
    font-weight: 100;
    width: 100%;
  }


  &.full {
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .headshot-container {
      width: 49%;
    }

    .description {
      width: 49%;
    }

  }
}


.article-wrapper {

  .container {
    padding-top: 38px;

    @include media-breakpoint-up(md) {
      padding-top: 60px;

    }
  }

  .body-text {
    font-family: saintecolombe;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px;
    font-weight: 100;
    @include media-breakpoint-up(md) {
      font-size: 22px;
      line-height: 40px;
      margin-bottom: 50px;
    }

    .text {

      margin-bottom: 24px;
      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.drop-cap {
        &:first-letter {
          font-size: 56px;
          float: left;
          line-height: 58px;
          margin-right: 5px;

          @include media-breakpoint-up(md) {
            font-size: 75px;
            line-height: 80px;
          }
        }
      }

      &.normal {
        font-weight: normal;
      }
    }

    &.drop-cap {
      p:first-child:first-letter {
        font-size: 56px;
        float: left;
        line-height: 58px;
        margin-right: 5px;

        @include media-breakpoint-up(md) {
          font-size: 75px;
          line-height: 80px;
        }
      }
    }

    &.bold {
      font-weight: bold;
    }
  }

  .key-messages {
    background: #ECECE3;
    padding: 35px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      padding: 45px;
      padding-left: 50px;
      padding-right: 50px;
      margin-bottom: 50px;
    }

    .title {
      font-family: Interstate;
      text-transform: uppercase;
      margin-bottom: 32px;
      font-size: 16px;
      @include media-breakpoint-up(md) {
        margin-bottom: 42px;
      }
    }

    p {
      font-family: Interstate;
      font-weight: 100;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, .3);
      font-size: 16px;
      line-height: 28px;

      @include media-breakpoint-up(md) {
        line-height: 32px;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }

    }
  }

  .quote {
    font-size: 22px;
    font-family: saintecolombe;
    line-height: 36px;
    padding: 40px 25px 60px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      font-size: 32px;
      line-height: 50px;
      font-weight: normal;
      padding-left: 45px;
      padding-right: 45px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 20px;
      margin-bottom: 50px;
      border-left: 1px solid rgb(0, 0, 0);
    }

    &:before {
      content: '';
      display: block;
      background-image: url("/img/icons/single-quote-left.svg");
      background-repeat: no-repeat;
      min-height: 18px;
      background-position: center;
      border: 0;
      background-size: 36px;
      margin-bottom: 30px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 100%;
        background-position: left;
        background-size: 42px;
        min-height: 30px;
        margin-bottom: 50px;
      }
    }

    p {
      margin-bottom: 34px;
      @include media-breakpoint-up(md) {
        margin-bottom: 46px;

      }
    }

    .author {
      font-family: interstate;
      font-size: 14px;
      line-height: 26px;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
      }

      &:after {
        content: ",";
        margin-left: -2px;
      }
    }

    .author-title {
      font-family: interstate;
      font-size: 14px;
      font-weight: 100;
      line-height: 23px;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .divider {
      width: 50px;
      height: 2px;
      background-color: rgba(0, 0, 0, .3);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 18px;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .download {
    background: $ltgrey;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    margin-bottom: 40px;

    .body {
      flex: 1;
      margin: 10px 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      margin-left: 20px;
      margin-right: 20px;
      line-height: 26px;
      font-family: saintecolombe;
      font-style: italic;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 40px;
        overflow: hidden;

      }
    }

    .download-icon {
      width: 75px;
      height: 75px;
      background-color: $citiblue;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("/img/icons/download-arrow-mobile.svg");
      background-repeat: no-repeat;
      background-position: center 18px;
      background-size: 12px 37px;
      transition: all 200ms ease-in-out;

      .download-line {
        display: none;
        height: 2px;
        width: 20px;
        background-color: $white;
        margin-top: 37px;
        @include media-breakpoint-up(md) {
          display: block;
        }
      }

      &:hover {
        transition: all 200ms;
        @include media-breakpoint-up(md) {
          background-position-y: 13px;
        }
      }

      @include media-breakpoint-up(md) {
        background-image: url("/img/icons/download-arrow.svg");
        background-size: 19px 32px;
      }

      img {
        transition: all 200ms ease-in-out;
        height: 20px;
        @include media-breakpoint-up(md) {
          height: 35px;
        }
      }
    }
  }

  .single-image {
    margin-bottom: 40px;

    .image-details {
      margin-bottom: 20px;
    }

    .name {
      font-family: Interstate;
      font-size: 14px;
      text-transform: uppercase;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .description {
      font-family: Interstate;
      font-size: 14px;
      font-weight: 100;
      line-height: 20px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
        text-transform: uppercase;
      }
    }

    .image-container {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }

      @include media-breakpoint-up(lg) {
        margin-left: -100px;
        margin-right: -100px;
      }

    }

    img {
      width: 100%;
    }

    .sources {
      font-size: 12px;
      color: $grey;
      line-height: 14px;

      @include media-breakpoint-up(md) {
        line-height: 16px;
      }
    }
  }

  .download-sidebar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    align-self: center;
    position: relative;

    &:hover {
      .icon {
        transition: all 300ms;
        transform: scale(1.1);
      }

      .text {
        background-size: 100% 1px;
      }

    }

    .icon {
      transition: all 300ms;
      height: 24px;

    }

    .text-wrapper {
      display: inline;
      margin-left: 10px;
      max-width: 120px;
      margin-top: 3px;
    }

    .text {
      font-family: Interstate;
      font-size: 16px;
      line-height: 24px;
      color: $citiblue;
      font-weight: 100;
      max-width: 120px;
      background-image: linear-gradient($citiblue, $citiblue);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      transition: background-size .3s;
    }
  }

  .caption {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    align-self: center;
    position: relative;

    .icon {
      margin-top: 3px;
    }

    .text {
      font-family: Interstate;
      font-size: 16px;
      line-height: 24px;
      font-weight: 100;
      display: inline;
      max-width: 120px;
      border-top: 1px solid rgba(0, 0, 0, .3);
      padding-top: 20px;
    }
  }

  .carousel {

    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }

    .owl-dots {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .owl-nav {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }

    .owl-item {
      opacity: 50;
      transition: .2s ease-in-out;

      &.active {
        opacity: 100;
        transition: opacity .2s ease-in-out;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

    }

    .item > div {
      width: 100%;
    }

    .text-container {
      width: 80%;
      margin-bottom: 50px;
      @include media-breakpoint-up(lg) {
        width: 50%;
      }

    }

    .image-container {
      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        height: 500px;
        margin-bottom: 15px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .title {
      font-family: saintecolombe;
      font-size: 22px;
      font-weight: 100;
      line-height: 32px;
      margin-bottom: 13px;
      @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 34px;
      }
    }

    .description {
      font-family: Interstate;
      font-size: 14px;
      line-height: 20px;
      font-weight: 100;
    }
  }

  .social-share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }

    .title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      font-weight: 100;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-right: 15px;
        margin-top: 4px;
      }
    }

    .icons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .icon {

      margin-left: 20px;
      margin-right: 20px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #E5E5E5;

      @include media-breakpoint-up(md) {
        background: #E5E5E5;
        width: 30px;
        height: 30px;
        border: 1.5px solid #E5E5E5;
        &:hover {
          background: transparent;
        }
      }

      img {
        height: 12px;
        width: 12px;
        @include media-breakpoint-up(md) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }


  .tags {
    display: flex;
    flex-direction: row;
    margin-bottom: 55px;

    .label:not(:first-child) {
      margin-left: 10px;
    }
  }

  .full-image {
    position: relative;
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
      margin-bottom: 42px;
    }

    .image-container {
      left: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      position: relative;
      right: 50%;
      width: 100vw;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      img {
        width: 100%;
      }
    }

    .bottom-caption {
      font-family: Interstate;
      font-size: 12px;
      color: $darkGrey;
      line-height: 16px;
      text-align: left;
      margin-top: 20px;
      margin-right: 20px;
      @include media-breakpoint-up(md) {
        margin-right: 110px;
        max-width: 180px;
      }
    }
  }

  .image-quote {
    margin-bottom: 70px;
    @include media-breakpoint-up(md) {
      margin-bottom: 90px;
    }

    .bottom-caption {
      font-family: Interstate;
      font-size: 12px;
      color: $darkGrey;
      line-height: 16px;
      text-align: left;
      margin-top: 10px;
      margin-left: 20px;
      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }

    .image-container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(md) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .image-wrapper {
        position: relative;
        width: 100%;
        padding-bottom: 100%; /* = width for a 1:1 aspect ratio */
        overflow: hidden;

        @include media-breakpoint-up(md) {
          padding: 0;
          overflow: unset;
          height: 100%;
        }
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        @include media-breakpoint-up(md) {
          position: unset;
        }
      }
    }

    .quote-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      .quote {
        font-size: 36px;
        line-height: 50px;
        border: 0;
        text-align: center;
        padding-bottom: 0;
        padding-top: 70px;

        @include media-breakpoint-up(md) {
          font-size: 44px;
          line-height: 65px;
        }

        &:before {
          background-position: center center;
          margin-bottom: 44px;
        }

        &:after {
          content: '';
          display: block;
          background-image: url("/img/icons/single-quote-right.svg");
          background-repeat: no-repeat;
          min-height: 18px;
          background-position: center;
          border: 0;
          background-size: 36px;
          margin-top: 30px;
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 100%;
            background-size: 42px;
            min-height: 30px;
            margin-top: 50px;
          }
        }
      }
    }
  }

  .video {
    width: 100%;
    margin-bottom: 50px;
    @include media-breakpoint-up(md) {
      margin-bottom: 90px;

    }

    .video-container {
      width: calc(100% + 30px);
      margin-left: -15px;
      position: relative;
      //padding-bottom: 56.25%; //For 16:9 aspect ratio
      padding-bottom: 56.25%;
      overflow: hidden;

      @include media-breakpoint-up(md) {
        width: 100%;
        margin-left: 0;

      }

      .thumbnail {
        width: 100%;
      }
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 56px;
      width: 56px;
      transform: translate(-50%, -50%);
      @include media-breakpoint-up(md) {
        height: 128px;
        width: 128px;
      }
    }
  }

  .author-by-line {

    position: relative;
    background: $khaki;
    margin-bottom: 60px;
    margin-left: -15px;
    margin-right: -15px;

    @include media-breakpoint-up(md) {
      margin-bottom: 100px;
      margin-left: 0;
      margin-right: 0;
    }

    .container {
      //padding: 0;
      padding-top: 0;
    }

    .image-container {
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(md) {

      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .text-container {
      padding-top: 40px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 100%;

      @include media-breakpoint-up(lg) {
        padding-left: 50px;
        padding-right: 50px;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 100px;
        padding-right: 100px;
      }

      .title {
        font-family: saintecolombe;
        font-weight: 100;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
          font-size: 32px;
          line-height: 50px;
        }
      }

      p {
        font-family: saintecolombe;
        font-size: 16px;
        font-weight: 100;
        line-height: 28px;
        width: 100%;
        @include media-breakpoint-up(md) {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }

  .further-reading {


    a {
      &:hover {
        color: $black;
      }
    }

    margin-bottom: 80px;
    @include media-breakpoint-up(md) {
      margin-bottom: 100px;
    }

    .item-wrapper {

      @include media-breakpoint-up(lg) {
        padding-left: 20px;
        padding-right: 20px;
      }

      &:first-child {
        @include media-breakpoint-up(lg) {
          padding-left: 15px;
        }
      }

      &:last-child {
        @include media-breakpoint-up(lg) {
          padding-right: 15px;
        }
      }
    }


    .image-container {
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 100%;
      position: relative;

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .row {
      margin: 0;
    }

    .main-title-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .main-title {
      font-family: saintecolombe;
      font-size: 24px;
      font-weight: 100;
      line-height: 34px;
      text-align: center;
      margin-bottom: 50px;
      max-width: 310px;

      @include media-breakpoint-up(md) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 80px;
        max-width: 100%;
      }
    }

    .carousel-container {

      .title {
        font-family: saintecolombe;
        font-weight: 100;
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          font-size: 22px;
          line-height: 32px;
        }
      }


      .author {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
      }
    }
  }

  .people {
    padding-top: 50px;
    padding-bottom: 60px;
    border-top: 1px solid rgba(0, 0, 0, .3);
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 70px;
    color: $black;

    .headshot {
      background: $white;
    }

    @include media-breakpoint-up(md) {
      padding-bottom: 50px;

      .person {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: $white;

        .headshot-container {
          width: 49%;
        }

        .description {
          width: 49%;
        }
      }
    }
  }

  .people-multi {
    padding-top: 50px;
    border-top: 1px solid rgba(0, 0, 0, .3);
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 70px;


    .person {
      margin-bottom: 60px;

      .headshot {
        background: $white;
      }

      @include media-breakpoint-up(md) {
        .headshot-container {
          margin-left: 20px;
          margin-right: 20px;
        }
        .description {
          width: 100%;
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }


  &.dark {

    background: #404040;
    color: $white;

    .container {
      background: $black;
    }

    a {
      color: $white;

      &:hover {
        color: $citiblue;
      }
    }


    .learn-more {
      color: #1AC2FF;

      svg {
        path {
          stroke: #1AC2FF;
        }
      }
    }

    .further-reading a:hover {
      color: $white !important;
    }

    .people, .people-multi {
      background: $white;
      color: $black;
      @include media-breakpoint-up(md) {
        padding-top: 30px;
        padding-bottom: 30px;

      }

      .person {
        background: $white;
      }

      .headshot {
        background: $white !important;
      }
    }

    .author-by-line {
      background: #404040;

      .container {
        background: transparent;
      }
    }

    .full-image {
      .bottom-caption {
        color: $white;
      }
    }

    .image-quote {
      .quote {
        &:before {
          background-image: url("/img/icons/single-quote-left-white.svg");
        }

        &:after {
          background-image: url("/img/icons/single-quote-right-white.svg");
        }
      }

      .bottom-caption {
        color: $white;
      }
    }

    .ma3_post-editorial-links {

      .item {
        border-bottom: 1px solid $white;
        @include media-breakpoint-up(md) {
          border-bottom: 0;
        }
      }
      .item-wrapper {

        @include media-breakpoint-up(md) {
          &:nth-child(2) {
            border-left: 1px solid $white !important;
            border-right: 1px solid $white !important;
          }
        }
      }
    }
  }
}

body.dark {

  background: $black;
  color: $white;

  a {
    color: $white;

    &:hover {
      color: $citiblue;
    }
  }

  .learn-more {
    color: #1AC2FF;

    svg {
      path {
        stroke: #1AC2FF;
      }
    }
  }
}





.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  ul {
    display: flex;
    margin-bottom: 0px;

    @include media-breakpoint-up(md) {
      margin-right: 40px;
    }

    li {
      display: block;
      padding: 0px 8px;
      font-size: 16px;
      color: #008CE6;
      font-family: "interstate";
      font-weight: 100;

      @include media-breakpoint-up(sm) {
        padding: 0px 12px;
      }

      &.spacer {
        padding: 0px;
      }
      a {
        color: #008CE6;
      }
      a.active {
        font-weight: bold;
      }
    }
  }

  .prev, .next {
    height: 45px;
    width: 45px;
    background-image: url("/img/icons/carousel-arrow.svg");
    background-size: 100%;

    &.disabled {
      pointer-events: none;
      background-image: url("/img/icons/carousel-inactive-arrow.svg");
    }
  }

  .prev {
    order: -1;
    margin-right: 18px;
    transform: rotate(180deg);

    &.disabled {
      transform: rotate(0);
    }

    @include media-breakpoint-up(md) {
      order: 0;
      margin-right: 0px;
    }
  }

  .next {
    margin-left: 18px;

    &.disabled {
      transform: rotate(180deg);
    }

    @include media-breakpoint-up(md) {
      margin-left: 27px;
    }
  }
}
