.m1ab_header {

  background: #ECECE3;

  @include media-breakpoint-up(md) {
    padding-bottom: 20px;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 30px;
  }

  .container {
    position: relative;
  }

  .text-container {
    padding-top: 53px;
    padding-bottom: 20px;
    @include media-breakpoint-up(md) {
      padding-top: 110px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 200px;
      padding-bottom: 55px;
    }

    h1 {
      text-align: center;
      padding-bottom: 25px;
      margin-bottom: 0px;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
        padding-bottom: 32px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 100;
      text-align: center;
      line-height: 24px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        text-align: left;
        width: 90%;
        letter-spacing: -0.01em;
        line-height: 1.5em;
      }
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 32px;
      }
    }

  }


  .hero-image-container {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .image-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      margin: -10px auto 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        margin: 0px;
      }

      img {
        width: 80%;

        @include media-breakpoint-up(md) {
          width: 100%;
          object-fit: contain;
          font-family: 'object-fit: contain;';
        }
        @include media-breakpoint-up(lg) {
        }

        @include media-breakpoint-up(xl) {
        }

      }
    }
  }


}


.breadcrumb-container + .m1ab_header {
  @include media-breakpoint-up(md) {
    margin-top: -20px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -30px;
  }
}
