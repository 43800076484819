.m9_use-cases {

  > .container {
    @include media-breakpoint-up(md) {
      padding: 100px 65px 50px !important;
    }
  }

  .info-text-container {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    .title {
      font-family: saintecolombe;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;
      font-weight: 100;
      @include media-breakpoint-up(lg) {
        font-size: 40px;
        line-height: 54px;
        margin-bottom: 50px;
      }
    }

    p {
      font-family: Interstate;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 30px;
      font-weight: 100;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .person-text-container {
    margin-bottom: 40px;

    .title {
      font-family: saintecolombe;
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 12px;
      @include media-breakpoint-up(lg) {
        line-height: 32px;
        margin-bottom: 16px;
      }
    }

    p {
      font-family: Interstate;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 30px;
      font-weight: 100;

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        line-height: 30px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cta-container {
    background: $ltgrey;
    padding: 20px 25px 50px;

    @include media-breakpoint-up(md) {
      padding: 50px 30px 55px 60px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 100px;
      margin-left: 50px;
    }

    @include media-breakpoint-up(xl) {
      margin-left: 50px;
    }


    .person {
      .title-container {
        padding-right: 0;
      }
      .headshot-container {
        margin-bottom: 20px;
      }
    }
  }
}


.m1c_header + .m9_use-cases {
  padding-top: 90px;
  margin-top: -150px;
  z-index: -1;
  position: relative;
  @include media-breakpoint-up(md) {
    margin-top: unset;
    padding-top: unset;
    z-index: 0;
  }
}
