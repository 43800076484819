
$tabletWidth: 375px;

.filter-menu {
  position: relative;
  z-index: 9998;
  color: white;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: $tabletWidth;
    float: right;
  }




  .filter-btn-container {
    text-align: center;
    width: 100%;
    display: block;

    @include media-breakpoint-up(md) {
      text-align: right;
    }

    .filter-menu-title {
      font-family: "interstate";
      font-size: 14px;
      font-weight: 100;
      margin-right: 12px;
    }

    .filter-btn {
      width: 28px;
      height: 28px;
      line-height: 25px;
      border-radius: 14px;

      @include media-breakpoint-up(md) {
        width: 38px;
        height: 38px;
        border-radius: 19px;
      }

      &.hidden {
        display: none;
      }
    }

    .filter-btn-icon-wrapper {
      position: relative;
      display: inline-block;
      .filter-count {
        display: inline-block;
        background: #77F9DB;
        color: black;
        text-align: center;
        border-radius: 19px;
        display: inline-block;
        padding-top: 2px;
        padding-right: 0px;
        font-weight: 400;
        font-size: 16px;


        width: 28px;
        height: 28px;
        line-height: 24px;
        border-radius: 14px;

        @include media-breakpoint-up(md) {
          width: 38px;
          height: 38px;
          line-height: 34px;
          border-radius: 19px;
        }

        &.hidden {
          display: none;
        }
      }
    }

    .close-filters-btn {
      display: none;
    }
    .show-filters-btn, .filter-count {
      display: inline-block;
    }

    &[aria-expanded=true] {
      .show-filters-btn, .filter-count {
        display: none;
      }
      .close-filters-btn {
        display: inline-block;
      }
    }


  }



  .filter-popout {
    z-index: 1;
    background: black;
    margin: 10px -15px 0px;
    @include media-breakpoint-up(md) {
      position:absolute;
      width: $tabletWidth;
      margin: 10px 0px 0px 0px;
    }

    .filter-popout-inner {
      padding: 25px;

      @include media-breakpoint-up(md) {
        max-height: 583px;
        overflow-y: scroll;
        scrollbar-color: #FFFFFF;

        //&::-webkit-scrollbar {
        //  width: 10px;
        //}
        //
        //&::-webkit-scrollbar-thumb {
        //  border-radius: 10px;
        //  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        //  background-color: #737272;
        //  border: 1px solid #000;
        //}

      }
    }
  }




  h5 {
    font-family: "saintecolombe";
    font-size: 20px;
    font-weight: 100;

    border-top: 1px solid white;
    padding-top: 60px;
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
  .filter-menu-section:first-of-type h5 {
    border-top: none;
    padding-top: 0px;
  }


  .filter-category-header-wrapper {
    position: relative;
    font-family: "interstate";
    padding:20px 0px 15px;



    .filter-category-header {

      display: block;
      font-family: "interstate";
      font-weight: 100;
      font-size: 18px;
      color: white;
      height: 26px;
      line-height: 26px;

      .chevron {
        display: block;
        position: absolute;
        right: 8px;
        top: 20px;
        svg {
          width: 13.5px;
          height: 9px;
        }
        path {
          stroke: white;
        }

        @include media-breakpoint-up(lg) {
          //display: none;
        }

        svg {
          $trans: all 0.2s;
          transition: $trans;
          transform: rotate(180deg);
        }
      }

      &[aria-expanded=true] {
        .chevron {
          svg {
            transform: rotate(0deg);
          }
        }

        .filter-count {
          display: none;
        }
        .clear-btn {
          display: inline-block;
          &.hidden {
            display: none;
          }
        }
      }

      .filter-count {
        display: inline-block;
        background: #77F9DB;
        color: black;
        width: 24px;
        height: 24px;
        line-height: 22px;
        text-align: center;
        border-radius: 15px;
        display: inline-block;
        padding-top: 2px;
        padding-right: 0px;
        font-weight: 400;
        font-size: 16px;
        margin-left: 12px;

        &.hidden {
          display: none;
        }
      }



    }

  }


  .clear-btn, .clear-all-btn {
    display: none;
    color: #008CE6;
    margin-left: 15px;
    font-weight: 100;
    font-size: 16px;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  .clear-all-btn {
    display: block;
    float:right;
    margin-left: 15px;
    font-weight: 100;
    font-size: 16px !important;
    font-family: "interstate";
    text-align: right;
  }

  .filter-menu-subsection {
    font-family: "interstate" !important;
    font-weight: 100;
    font-size: 16px;
    color: white;

    border-bottom: 1px solid white;
    &:last-of-type {
      border-bottom: none;
    }

    .checkbox-container {
      font-weight: 100;
      font-size: 16px;
    }

  }




  /* Customize the label (the container) */
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border-bottom: 1px solid #696565;
    padding-bottom: 13px;
    padding-top: 4px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 2px;
    }
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 1px;
    height: 20px;
    width: 20px;
    background-color: black;
    border: 1px solid white;
  }

  /* On mouse-over, add a grey background color */
  /*.checkbox-container:hover input ~ .checkmark, */ .checkbox-container input:focus ~ .checkmark {
    background-color: #ccc !important;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #77F9DB;
    border: 1px solid #77F9DB;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 13px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }



  .datepicker-input-label {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    span {
      width: 70px;
      padding-top: 2px;
    }
    input {
      text-align: center;
      outline: none !important;
      font-family: interstate, Sans-Serif;
      border-radius: 0;
      background-color: #5C5C5C;
      border: 0;
      color: #FFFFFF;
      font-size: 12px;
    }
  }
}


.ui-datepicker {
  z-index: 9998 !important;
  background: black;
  font-family: "interstate";
  * {
    font-family: "interstate";
  }

  .ui-state-active, .ui-widget-content .ui-state-active {
    border: 1px solid white;
    background: #5C5C5C !important;
  }

  td {
    a {
      text-align:center;
    }
  }

  .ui-datepicker-current-day {
    border: 1px solid black !important;
    background: black !important;
    color: white !important;
  }

  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid white;
    background: black;
    font-weight: normal;
    color: white;
    font-family: "interstate";
  }

  .ui-button.ui-state-disabled:hover,.ui-state-hover {

    border: 1px solid white !important;;
    background: #5C5C5C !important;;
  }


  .ui-datepicker-today a {
    background: black;
    color: #77F9DB;
    font-weight: bold;
  }


  .ui-datepicker-header {
    background: black;
    border: none;
  }
  .ui-widget-content {
    border:none;
  }

  .ui-datepicker-month, .ui-datepicker-year {
    background:black;
    color:white;
    padding: 2px 5px;
    font-family: "interstate";
  }
}

.ui-datepicker table {
  background: black;
}
.ui-datepicker .ui-datepicker-header {
  background: black;
}
.ui-datepicker .ui-datepicker-header {
  background: black;
}
.ui-datepicker .ui-datepicker-prev.ui-state-hover, .ui-datepicker .ui-datepicker-next.ui-state-hover {
  background: black;
}
.ui-datepicker-calendar {
  color:white;
}
