.m17_featured-quotes {

  &.container {
    background: $white;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
  }

  .title-container {
    background: #ECECE3;
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 236px;
    padding-top: 50px;
    height: 100%;
    @include media-breakpoint-up(md) {

      padding-bottom: 252px
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0;
      margin-right: -30px;
      padding-left: 30px;
      text-align: left;
      align-items: flex-start;
      padding-right: 30px;

    }

    @include media-breakpoint-up(xl) {
      padding-left: 80px;
      padding-right: 40px;
    }


    .title {
      font-size: 26px;
      font-family: saintecolombe;
      line-height: 36px;
      font-weight: 100;
      max-width: 310px;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        font-size: 45px;
        line-height: 55px;
        max-width: unset;
      }

    }

    .body {
      font-family: Interstate;
      line-height: 22px;
      max-width: 325px;
      font-weight: 100;
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 32px;
        max-width: unset;
        margin-bottom: 30px;
      }
    }
  }

  .carousel-container {
    margin-top: -180px;
    @include media-breakpoint-up(md) {
      margin-top: -205px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0;
    }
  }

  .item {

    padding-bottom: 20px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }

    .image {
      width: 100%;
      height: 220px;

      @include media-breakpoint-up(md) {
        height: 350px;
      }

      @include media-breakpoint-up(lg) {
        height: 240px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text-container {
      padding: 20px;
    }

    .quote {
      font-family: saintecolombe;
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .role-location-wrapper {
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: flex-end;
      .line {
        width: 2px;
        height: 39px;
        background-color: #008CE6;
      }
    }

    .role-location-container {
      border-left: 2px solid #008CE6;
      padding-left: 10px;
    }

    .role {
      font-family: Interstate;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 28px;
    }

    .location {
      font-family: Interstate;
      font-size: 14px;
      font-weight: 100;
      text-transform: uppercase;
      color: rgba(0,0,0, .75);
      margin-top: -3px;
    }
  }

  .owl-stage {

    @include media-breakpoint-up(lg) {
      padding-left: 0 !important;
      padding-right: 0 !important
    }
  }

  .carousel-wrapper {
    @include media-breakpoint-up(lg) {
      padding-right: 0;
    }
  }

  .title-wrapper {
    @include media-breakpoint-up(lg) {
      margin-top: -30px;
      margin-bottom: -30px;
      padding-left: 0;
    }
  }

  .owl-nav {
    position: static;
    justify-content: unset;
    transform: unset;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    .owl-prev {
      margin-left: 0;
      padding: 0;
      margin-right: 30px;
    }

    .owl-next {
      padding: 0;
      margin-right: 0;
    }
  }

  .owl-item {
    opacity: 50%;
    transition: opacity .2s ease-in-out;

    &.active {
      opacity: 100;
      transition: opacity .2s ease-in-out;
    }
  }
}
