.m16_content-accordion {

  @include media-breakpoint-up(lg) {
    padding-bottom: 120px;
  }

  .accordion-container {
    margin-top: 14px;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  .item-wrapper {

    @include media-breakpoint-up(lg) {

      &:hover {
        .item {
          transition: all 300ms ease-in-out;
          transform: translateY(-10px);

          .expand-container {
            transition: all 300ms ease-in-out;
            right: 10px;
            top: 10px;
            opacity: 100;
          }
        }
      }
    }

    &:nth-child(2n) {
      @include media-breakpoint-up(lg) {
        border-left: 1px solid rgba(0, 0, 0, 0.6);
        border-right: 1px solid rgba(0, 0, 0, 0.6);
      }
    }
  }

  .item {
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    padding-top: 30px;
    position: relative;
    padding-bottom: 30px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      border-top: 0;
      padding-right: 40px;
      padding-left: 15px;
    }

    .title {
      font-family: saintecolombe;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 18px;
      @include media-breakpoint-up(lg) {
        font-size: 34px;
        font-weight: 100;
        line-height: 44px;
        max-width: 200px;
      }
    }

    .body {
      font-family: Interstate;
      font-size: 16px;
      color: #707070;
      font-weight: 100;
      line-height: 24px;
      padding-right: 12px;
      @include media-breakpoint-up(lg) {
        padding-right: 0;
      }
    }

    .expand-container {
      position: absolute;
      top: 10px;
      cursor: pointer;
      right: 0;
      transition: all 300ms ease-in-out;


      @include media-breakpoint-up(lg) {
        right: 10px;
        opacity: 40%;
      }

      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}


.content-accordion-modal {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  background: $khaki;
  z-index: $zIndexNavbar + 1;
  padding: 20px;
  top: 100vh;
  transition: all .3s ease-in-out;
  opacity: 0;

  @include media-breakpoint-up(lg) {
    padding: 100px 40px;
    overflow: scroll;
  }

  &.open {
    top: 0;
    opacity: 100;
  }


  .content {
    padding: 26px 20px 19px 20px;;
    background: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      padding: 44px;
      padding-bottom: 120px;
      min-height: 600px;
    }
  }

  .content-container {
    height: 100%;
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
    overflow: auto;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      height: 100%;
      margin: 0;
      padding: 0;
      align-items: stretch;
      justify-content: flex-start;
      padding-top: 20px;
      overflow: scroll;
    }
  }

  .content-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-bottom: 40px;
      justify-content: flex-end;
    }

    .title {
      font-size: 26px;
      font-family: saintecolombe;
      font-weight: 100;
      @include media-breakpoint-up(lg) {
        font-size: 34px;
        line-height: 44px;
        display: none;
      }
    }
  }


  .close-container {
    cursor: pointer;
    margin-bottom: 1px;
    img {
      width: 28px;
      height: 28px;
      @include media-breakpoint-up(lg) {
        width: 34px;
        height: 34px;
      }
    }
  }

  .left-column {
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      width: 40%;
      height: 100%;
      margin-left: 20px;
      min-height: 400px;
    }
    .title {
      font-family: saintecolombe;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 15px;
      display: none;
      @include media-breakpoint-up(lg) {
        font-size: 34px;
        font-weight: 100;
        line-height: 44px;
        display: block;
        margin-bottom: 25px;
        margin-top: -1px
      }
    }
  }

  .image-video-container {
    width: 100%;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    position: relative;
    overflow: hidden;
    margin-bottom: 27px;

    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-bottom: 0;
    }

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .text-container {
    //overflow: scroll;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 10px;
    // Hide scroll bar
    //-ms-overflow-style: none;  /* IE and Edge */
    //scrollbar-width: none;  /* Firefox */




    p {
      font-weight: 100;
      line-height: 24px;
      font-size: 16px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-grow: 1;
      overflow: auto;
      margin-left: 30px;
      min-height: 100%;
      margin-right: 50px;
      margin-top: 0px;

      width: 100%;
      display: flex;
      /* justify-content: center; */
      align-items: center;

      p {
        font-size: 16px;
        font-family: interstate;
        padding-right: 80px;
        padding-left: 40px;
        line-height: 28px;
        max-width: 1100px;

      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
      padding-left: 100px;
    }
  }
}
