$aqua: #77F9DB;
$ltblue: #00AEEF;
$ltbeige: #FDF9EC;
$khaki: #E2DAD0;
$ltgrey: #F2F6F7;
$white: #FFFFFF;
$grey: #B9B9B9;
$darkGrey: #696565;
$black: #000000;
$citiblue:    #008ce6 !default;

$cappuccino: #ECECE3;


$transparent-overlay: rgba(0,0,0,.6);
