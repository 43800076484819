.m4b-client-stories {
  display: none;
  padding-top: 10px;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  .main-container {
    @include media-breakpoint-up(lg) {
      //flex-direction: row-reverse;
    }
  }

  .client-story-maincard-wrapper {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }


  .image-container {
    height: 320px;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      height: 500px;
    }

    @include media-breakpoint-up(lg) {
      height: 710px;
    }
    img {
      width: 100%;
      //object-fit: cover;
      @include media-breakpoint-up(lg) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .label {
      position: absolute;
      left: 20px;
      top: 30px;

      @include media-breakpoint-up(md) {
        left: 40px;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }

    }
  }

  .no-pad-left-lg {
    @include media-breakpoint-up(lg) {
      padding-left: 0px;
    }
  }
  .no-pad-right-lg {
    @include media-breakpoint-up(lg) {
      padding-right: 0px;
    }
  }

  .no-pad-xs {
    @include media-breakpoint-only(xs) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .no-pad-sm {
    @include media-breakpoint-only(sm) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .text-container {
    background: $black;
    color: $white;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 40px;


    @include media-breakpoint-up(md) {
      padding: 50px 25px;
    }

    @include media-breakpoint-up(lg) {
      padding: 100px 25px;

      height: 710px;
    }

    .label {
      display: none;
      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }
    h1 {
      font-family: saintecolombe;
      font-weight: 100;
      font-size : 40px;
      margin: 0;
      @include media-breakpoint-up(md) {
        font-size: 90px;
        line-height: 100px;
        margin-top: 24px;
      }
    }

    p {
      font-size: 16px;
      font-family: saintecolombe;
      line-height: 22px;
      margin-top: 20px;
      font-weight: 100;
      height: 70px;
      display: block; /* Fallback for non-webkit */
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include media-breakpoint-up(md) {
        font-size: 22px;
        line-height: 36px;
        margin-top: 30px;
        height: 110px;
      }
    }
  }

  .cta {
    margin-top: 30px;
    color: $white;
    font-family: interstate;
    line-height: 24px;
    color: $white;
    font-weight: 100;

    @include media-breakpoint-up(md) {
      margin-top: 90px;
    }

    .arrow {
      width: 12px;
    }
  }



  @include media-breakpoint-up(md) {
    .client-stories-card {
      padding-right: 5px;
    }

    .client-stories-card:last-of-type {
      padding-right: 15px;
      padding-left: 5px;

    }
  }

  a.client-stories-card {
    &:hover {
      color: $black;
    }
  }

  .owl-carousel .citi-card {
    padding-bottom: 0px;
    margin-top: 0px;
  }
}
