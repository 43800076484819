.m1d_header {
  position: relative;
  height: calc(100vh - 50px);

  @include media-breakpoint-up(md) {
    height: 900px;
  }

  .background-image-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .image-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      margin: 0;
      display: block;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-height: none;
      max-width: none;
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
  }

  .container {
    height: calc(100% - 75px);
    @include media-breakpoint-up(md) {
      height: calc(100% - 60px);
    }
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
  }

  .text-container {
    color: $white;
    font-family: saintecolombe;
    font-weight: 100;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      width: 35%;
    }
    h1 {
      font-size: 38px;
      line-height: 50px;
      font-weight: 100;
      @include media-breakpoint-up(md) {
        font-size: 44px;
        line-height: 65px;
      }
    }

    p {
      font-size: 18px;
      line-height: 30px;
      @include media-breakpoint-up(md) {
        font-size: 21px;
        line-height: 32px;
      }
    }


    .author {
      font-family: Interstate;
      font-size: 16px;
      line-height: 33px;
      margin-bottom: 40px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 33px;
      }
    }
  }
}


.breadcrumb-container + .m1d_header {
  @include media-breakpoint-up(md) {
    margin-top: -20px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -30px;
  }
}

.breadcrumb-container ~ .m1d_header {
  color: $white !important;
}
