/*!
 ****************************************************
 ****************************************************
 ****************************************************
 * FOOTER.SCSS - START
 ****************************************************
 ****************************************************
 ****************************************************
 */

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

footer {
  font-family: interstate, sans-serif;
  background: black;
  color: white;
  font-weight: 100;
  font-size: 12px;
  padding-bottom: 22px;
  @include media-breakpoint-up(sm) {
    padding-bottom: 50px;
  }

}
.footer-container {

  .footer-logo {
    padding-top: 38px;
    padding-bottom: 28px;

    @include media-breakpoint-up(sm) {
      padding-top: 50px;
      padding-bottom: 20px;
    }
  }


  .nav-list {

    margin-bottom: 7px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 8px;
    }
  }

  .footer-nav-item {
    display: block;
    padding-bottom: 7px;


    @include media-breakpoint-up(sm) {
      padding-bottom: 5px;
    }

    a {
      font-size: 14px;
      color: white;
      cursor: pointer;
      &:hover {
        color: $citiblue !important;
      }
    }
  }

  .social-icons {
    display: flex;
    flex-direction: row;
    padding-bottom: 23px;
    align-items: center;

    @include media-breakpoint-up(sm) {
      padding: 20px 0px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
      padding-bottom: 20px;
      path {
        fill: white;
      }
    }

    .social-link {
      width: 30px;
      svg {
        display: inline-block;
        height: 20px;
        path {
          fill: white;
        }
      }
      &.youtube {
        margin-right: 25px;
        margin-bottom: -2px;

      }

      &.youtube svg {
        height: 32px;
      }

      &:hover {
        svg path {
          fill: $citiblue;
        }
      }
    }
  }




  .finra-disclaimer {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &.d-sm-none {
      .mag-text-container {
        //align-items: center;
      }
    }

    .finra-icon {
      width: 57px;
      margin-right: 16px;
    }

    .mag-text-container {

      display: flex;
      align-items: flex-start;

      .finra-mag {
        margin-right: 5px;
        width: 17px;
        margin-top: 2px;

        @include media-breakpoint-up(sm) {
          width: 12px;
        }
      }
      span {
        margin-top: 1px;
        line-height: 14px;
        font-size: 10px;
      }
    }

  }


  .right-col {

    line-height: 1.8em;

    @include media-breakpoint-up(sm) {
      padding-top: 50px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
    }
    p {
      font-size: 12px;
    }

    div.fdic-insured-disclaimer {
      border: 1px solid white;
      padding: 20px 10px;
      width: 100%;
      margin-bottom: 1rem;

      p {
        padding: 0px;
        margin: 0px;
        line-height: -0.2em;
        font-size: 14px;
        text-align: center;
        @include media-breakpoint-up(sm) {
          font-size: 12px;
        }
      }

    }


    a {
      color: white;
      &:hover {
        color: $citiblue !important;
      }
    }

  }
}



/*!
 ****************************************************
 ****************************************************
 ****************************************************
 * FOOTER.SCSS - END
 ****************************************************
 ****************************************************
 ****************************************************
 */
