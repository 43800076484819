.home-page {
  visibility: hidden;
  background: #404040;
  padding-bottom: 200px;
  color: $white;
  overflow: hidden;
  //height: 200vh;

  .learn-more {
    color: #1AC2FF;

    svg {
      path {
        stroke: #1AC2FF;
      }
    }
  }


  section {
    //padding-bottom: 100px;
    @include media-breakpoint-up(lg) {
      //margin-bottom: -100px;
    }
  }

  .text-container {
    max-width: 245px;
    text-align: center;
    padding-bottom: 50px;
    padding-top: 50px;
    position: relative;
    z-index: 999;

    @include media-breakpoint-up(md) {
      padding-top: 150px;
      padding-bottom: 150px;
      max-width: 340px;
    }

    @include media-breakpoint-up(lg) {
      text-align: left;
      max-width: 400px;
      //padding: 0;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;

    .text-container {
      text-align: center;
    }
  }

  .col-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      text-align: left;
    }

    &.align-start {
      justify-content: flex-start;
    }

    &.align-end {
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;

      }
    }
  }

  h1 {
    font-family: Interstate;
    font-weight: 100;
    line-height: 39px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
    @include media-breakpoint-up(lg) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  p {
    font-family: saintecolombe;
    font-size: 24px;
    line-height: 38px;
    font-weight: 100;

    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 34px;
      line-height: 44px;
      font-weight: 100;
    }
  }

  .italic {
    font-style: italic;
  }

  .parallax {
    position: absolute;
  }

  .starting-parallax {
    transform: translateY(25vh);
  }

  .hero-section {
    position: relative;


    @include media-breakpoint-up(lg) {
      margin-bottom: 100px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 250px;
    }
    @include media-breakpoint-up(xxl) {
      //margin-bottom: 430px;
    }


    .text-container {
      padding-bottom: 140px;
      padding-top: 140px;

      @include media-breakpoint-up(md) {
        padding-bottom: 125px;
        padding-top: 200px;
        max-width: 300px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 550px;
        padding-bottom: 200px;
        padding-top: 200px;
      }
      @include media-breakpoint-up(xl) {
        padding-bottom: 300px;
        padding-top: 300px;
      }
    }

    p {
      font-family: saintecolombe;
      font-size: 36px;
      line-height: 50px;
      font-weight: 100;
      @include media-breakpoint-up(md) {
        font-size: 44px;
        line-height: 50px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 56px;
        line-height: 70px;
      }
    }

    .image-1 {
      width: 337px;
      left: -211px;
      top: -12px;
      @include media-breakpoint-up(md) {
        width: 65%;
        top: -60px;
        left: -31%;
      }

      @include media-breakpoint-up(lg) {
        width: 68%;
        top: -176px;
        left: -35%;
      }
      @include media-breakpoint-up(xl) {
        width: 68%;
        top: -176px;
        left: -33%;
      }
      @include media-breakpoint-up(xxl) {
        width: 68%;
        top: -224px;
        left: -32%;
      }
    }

    .image-2 {
      width: 308px;
      right: -188px;
      top: 146px;
      transform: rotate(6deg);

      @include media-breakpoint-up(md) {
        width: 63%;
        top: -28px;
        right: -30%;
      }

      @include media-breakpoint-up(lg) {
        width: 71%;
        top: 100px;
        right: -36%;
      }
      @include media-breakpoint-up(xxl) {
        width: 73%;
        right: -34%;
      }
    }

    .image-3 {
      width: 182px;
      top: 47px;
      transform: rotate(-28deg);
      right: 13px;
      @include media-breakpoint-up(md) {
        width: 36%;
        right: 12%;
      }
      @include media-breakpoint-up(lg) {
        width: 31%;
        top: 100px;
        right: 16%;
      }
      @include media-breakpoint-up(xl) {
        width: 31%;
        right: 16%;
      }
    }

    .image-4 {
      width: 208px;
      top: 409px;
      transform: rotate(-2deg);
      left: 28px;
      @include media-breakpoint-up(md) {
        width: 36%;
        top: 410px;
        transform: rotate(-2deg);
        left: 26%;
      }

      @include media-breakpoint-up(lg) {
        width: 36%;
        top: 512px;
        transform: rotate(-2deg);
        left: 23%;
      }
      @include media-breakpoint-up(xl) {
        width: 36%;
        transform: rotate(-2deg);
        left: 23%;
      }
      @include media-breakpoint-up(xxl) {
        width: 34%;
        transform: rotate(-2deg);
        left: 26%;
      }
    }

    .image-5 {
      width: 138px;
      top: 0;
      left: 61px;
      @include media-breakpoint-up(md) {
        width: 29%;
        top: -13px;
        left: 20%;
      }
      @include media-breakpoint-up(lg) {
        width: 29%;
        top: -230px;
        left: 20%;
      }
      @include media-breakpoint-up(xxl) {
        width: 31%;
      }
    }
  }

  .beyond-borders-section {
    position: relative;

    .text-container {
      padding-top: 0;
      @include media-breakpoint-up(md) {
        margin-top: -300px;
        padding-top: 0px;
        padding-bottom: 40px;
      }
      @include media-breakpoint-up(xl) {
        padding-top: 0;
        padding-bottom: 40px;
      }
    }

    .layered-images {
      margin-top: 20px;
      min-height: 550px;
      position: relative;
      width: 340px;

      @include media-breakpoint-up(md) {
        width: 100%;
        min-height: 620px;
      }

      @include media-breakpoint-up(xl) {
        margin-top: -293px;
      }

      img {
        position: absolute;
      }

      .image-1 {
        width: 484px;
        left: -152px;
        top: -136px;
        @include media-breakpoint-up(md) {
          width: 100%;
          top: -22%;
        }
        @include media-breakpoint-up(lg) {
          width: 135%;
          top: -22%;
          left: -46%;
        }
        @include media-breakpoint-up(xxl) {
          width: 152%;
          top: -37%;
          left: -49%;
        }
      }

      .image-2 {
        width: 240px;
        top: 74px;
        right: 9px;
        @include media-breakpoint-up(md) {
          width: 60%;
          top: 10%;
        }
        @include media-breakpoint-up(lg) {
          right: 12%;
        }
        @include media-breakpoint-up(xl) {
          width: 77%;
          top: 26%;
          right: -7%;
        }
      }

      .image-3 {
        width: 290px;
        top: 300px;
        right: 26px;

        @include media-breakpoint-up(md) {
          width: 60%;
          top: 61%;
        }
        @include media-breakpoint-up(lg) {
          width: 71%;
          right: 15%;
        }
        @include media-breakpoint-up(xl) {
          width: 72%;
          top: 93%;
          left: 21%;
        }
        @include media-breakpoint-up(xxl) {
          width: 72%;
          top: 105%;
          left: 21%;
        }
      }

      .image-4 {
        width: 276px;
        left: -36px;
        top: 4px;

        @include media-breakpoint-up(md) {
          width: 51%;
          left: 12%;
        }
        @include media-breakpoint-up(lg) {
          width: 59%;
          right: -20%;
          top: -10%;
        }
        @include media-breakpoint-up(xl) {
          width: 74%;
          left: -4%;
          top: 13%;
        }
      }

    }
  }

  .commitment-section {
    position: relative;

    .layered-images {
      min-height: 270px;
      position: relative;
      width: 340px;
      @include media-breakpoint-up(md) {
        width: 100%;
        min-height: 337px;
      }

      img {
        position: absolute;
      }

      .image-1 {
        width: 213px;
        left: 20px;
        @include media-breakpoint-up(md) {
          width: 51%;
          left: 14%;
        }
        @include media-breakpoint-up(lg) {
          left: auto;
          right: 11%;
          top: -33%;
          width: 29%;
        }
        @include media-breakpoint-up(xl) {
          left: auto;
          right: 16%;
          top: 30%;
          width: 21%;
        }
        @include media-breakpoint-up(xxl) {
          left: auto;
          right: 16%;
          top: 51%;
          width: 21%;
        }
      }

      .image-2 {
        width: 381px;
        top: 31px;
        right: -140px;
        @include media-breakpoint-up(md) {
          width: 71%;
          right: -2%;
          top: 26%;
        }
        @include media-breakpoint-up(lg) {
          width: 41%;
          right: -7%;
          top: -10%;
        }
        @include media-breakpoint-up(xl) {
          width: 48%;
          right: -18%;
          top: 31%;
        }
        @include media-breakpoint-up(xxl) {
          width: 48%;
          right: -18%;
          top: 56%;
        }
      }
    }

    .text-container {
      @include media-breakpoint-up(md) {
        padding-top: 115px;
        padding-bottom: 70px;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 0;
        padding-bottom: 150px;
        margin-top: -61px;
      }
      @include media-breakpoint-up(lg) {
        //margin-top: 162px;
      }
      @include media-breakpoint-up(lg) {
        //margin-top: 245px;
      }
    }

    .image-container {

      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        @include media-breakpoint-up(lg) {
          position: absolute;
          left: -4%;
          width: 439px;
          top: -269px;

        }
        @include media-breakpoint-up(xl) {
          position: absolute;
          left: -4%;
          width: 529px;
          top: -311px;

        }
        @include media-breakpoint-up(xxl) {
          position: absolute;
          left: -10%;
          width: 658px;
          top: -311px;

        }

      }
    }

    .image-3 {
      position: relative;
      top: -21px;
    }
  }

  .established-section {
    position: relative;
    @include media-breakpoint-up(xl) {
      margin-top: 200px;
    }

    .text-container {
      padding-top: 20px;
      @include media-breakpoint-up(md) {
        padding-top: 38px;
        padding-bottom: 52px;
      }
      @include media-breakpoint-up(lg) {
        padding-top: 0;
        margin-top: -217px;
      }
    }

    .item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .image-container {
        @include media-breakpoint-up(lg) {
          padding-bottom: 50px;
          width: 100%;
        }

        img {
          width: 100%;
        }
      }

      .text-container {
        text-align: center;
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 200px;
        visibility: hidden;

        @include media-breakpoint-up(lg) {
          max-width: unset;
          margin-top: 40px;
          text-align: left;
          width: 100%;
        }

        .date {
          font-family: Interstate;
          font-size: 22px;
          line-height: 32px;
          font-weight: 100;
          font-style: italic;
        }

        b {
          max-width: 200px;
          font-size: 22px;
          line-height: 32px;
          font-weight: 100;
        }
      }

      .text-wrapper {
        @include media-breakpoint-up(lg) {
          max-width: 200px;
        }
      }
    }

    .carousel-container {
      position: relative;
    }

    .carousel-timeline {
      width: 100%;
      position: relative;

      .line {
        height: 1px;
        width: 100%;
        background: $white;
      }

      .checkpoint-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .checkpoint {
        height: 40px;
        width: 2px;
        background: $white;
        position: relative;
        top: -20px;
        opacity: 0;

        &.active {
          opacity: 100;
        }
      }
    }


  }


  .global-section {
    pointer-events: none;
    @include media-breakpoint-up(lg) {
      margin-bottom: 120px;
      margin-top: -100px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 170px;
      margin-top: -100px;
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: 275px;
    }

    .text-container {
      padding-top: 0;
      padding-bottom: 200px;
      @include media-breakpoint-up(md) {
        padding-bottom: 0px;
        max-width: 340px;
      }
      @include media-breakpoint-up(xl) {
        margin-bottom: -198px;
      }
    }

    .layered-images {
      min-height: 240px;
      position: relative;
      width: 340px;
      @include media-breakpoint-up(md) {
        width: 100%;
        min-height: 390px;
      }
      @include media-breakpoint-up(lg) {
        height: unset;
        min-height: 212px;
      }

      img {
        position: absolute;
      }


      .image-1 {
        width: 113%;
        top: 15%;
        left: -9%;
        @include media-breakpoint-up(md) {
          width: 113%;
          top: 5%;
          left: -11%;
        }
        @include media-breakpoint-up(lg) {
          width: 64%;
          top: -58%;
          left: -17%;
        }
        @include media-breakpoint-up(xl) {
          width: 64%;
          top: -75%;
          left: -17%;
        }
        @include media-breakpoint-up(xxl) {
          width: 64%;
          top: -86%;
          left: -23%;
        }
      }

      .image-2 {
        width: 59%;
        top: 11%;
        right: 9%;
        @include media-breakpoint-up(md) {
          width: 64%;
          top: 10%;
          right: 0;
        }
        @include media-breakpoint-up(lg) {
          width: 27%;
          top: -36%;
          right: unset;
          left: 9%;
        }
        @include media-breakpoint-up(xl) {
          width: 27%;
          top: -44%;
          right: unset;
          left: 9%;
        }
        @include media-breakpoint-up(xxl) {
          width: 23%;
          top: -75%;
          right: unset;
          left: 2%;
        }


      }
    }

    .layered-images-2 {
      min-height: 245px;
      position: relative;
      width: 340px;
      @include media-breakpoint-up(md) {
        min-height: 431px;
        position: relative;
        width: 100%;
      }

      img {
        position: absolute;
      }


      .image-1 {
        width: 252px;
        top: 1%;
        right: 0;
        @include media-breakpoint-up(md) {
          width: 65%;
          top: -7%;
          right: -1%;
        }
        @include media-breakpoint-up(lg) {
          width: 115%;
          top: 0%;
          right: -1%;
        }
        @include media-breakpoint-up(xl) {
          top: -7%;
        }
        @include media-breakpoint-up(xxl) {
          right: -17%;
        }
      }

      .image-2 {
        width: 128px;
        top: 25%;
        left: 0;
        @include media-breakpoint-up(md) {
          width: 40%;
          top: 14%;
          left: 4%;
        }
        @include media-breakpoint-up(lg) {
          width: 40%;
          top: 98%;
          left: -71%;
        }
        @include media-breakpoint-up(xl) {
          top: 96%;
          left: -56%;
          width: 34%;
        }
      }
    }
  }


  .alert-info {
    background-color: #696565;
    min-height: 40px;
    color: $white;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 100;
    position: relative;
    z-index: 999;

    @include media-breakpoint-up(md) {
      margin-bottom: 70px;
    }

    a {
      color: $white;
      text-decoration: underline;
    }

    .message-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;
      @include media-breakpoint-up(md) {
        align-items: center;
      }

    }


    .warning-icon, .close-icon {
      margin-top: 5px;
      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }

    .message {
      padding-left: 20px;
      padding-right: 30px;
      font-size: 14px;
      line-height: 20px;
      flex: 1;

      @include media-breakpoint-up(md) {
        padding-left: 20px;
        padding-right: 60px;
      }
    }

    .warning-icon {
      width: 22px;
      height: 22px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }


  .owl-carousel {
    .owl-dot {
      background-color: $white !important;
    }
  }

  .owl-carousel .owl-stage {
    display: flex;

  }

  .owl-item.active {
    .text-container {
      visibility: visible;
    }
  }


  .owl-nav {
    top: auto;
    bottom: 0;
    right: 20px;
    justify-content: flex-end;
    transform: unset;

    .owl-next {
      margin-left: 20px !important;

    }

    .owl-prev, .owl-next {
      &.disabled {
        .owl-left-arrow, .owl-right-arrow {
          background-image: url("/img/icons/carousel-inactive-arrow-white.svg");
        }
      }
    }

    .owl-left-arrow, .owl-right-arrow {
      background-image: url("/img/icons/carousel-arrow-white.svg");
    }
  }

}


.home-page-modules {
  background: #404040;

  visibility: hidden;

  @include media-breakpoint-down(lg) {
    padding-top: 60px;
  }

  .m13-insights {

    color: $white !important;

    &.standard-module {
      padding-top: 0;
      padding-bottom: 65px;

      @include media-breakpoint-up(md) {
        padding-bottom: 170px;
      }
    }

    a, .byline, .byline-date {
      color: $white !important;
    }

    .card-footer-body.footer-body-padded {
      background: $black;
    }

    .module-header {
      @include media-breakpoint-down(md) {
        align-items: center;
        text-align: center;
      }

      h1 {
        font-family: Interstate !important;
        font-weight: 100 !important;
        line-height: 39px !important;
        font-size: 12px !important;
        text-transform: uppercase;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          margin: 0;
        }
        @include media-breakpoint-up(lg) {
          font-size: 22px !important;
          line-height: 32px !important;
        }
      }

      p {
        font-family: saintecolombe !important;
        font-size: 24px !important;
        line-height: 38px !important;
        font-weight: 100 !important;
        text-align: center;

        @include media-breakpoint-up(lg) {
          text-align: left;
          font-size: 28px !important;
        }

        @include media-breakpoint-up(lg) {
          font-size: 34px !important;
          line-height: 44px !important;
        }
      }

      .learn-more {
        display: none;
      }
    }

    .insight-masonry-wrapper.insight-masonry-wrapper-active::before {
      background-image: none;
    }

    .insight-masonry-wrapper.insight-masonry-wrapper-active::after {
      background-image: none;
    }
  }

  .m4c_client-stories {

    color: $white !important;

    a, .byline, .byline-date {
      color: $white !important;
    }

    .card-footer-body.footer-body-padded {
      background: $black;
    }

    &.standard-module {
      padding-top: 0;
      padding-bottom: 65px;

      @include media-breakpoint-up(md) {
        padding-bottom: 100px;
      }
    }

    .module-header {
      h1 {
        font-family: saintecolombe;
        font-size: 36px;
        text-align: center;
        margin-bottom: 20px;
        line-height: 40px;

        @include media-breakpoint-up(md) {
          font-size: 70px;
          text-align: left;
          margin: 0 0 0 -5px;
          max-width: 80%;
          line-height: 82px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 90px;
          line-height: 100px;
        }
      }


      p {
        font-family: interstate;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        font-weight: 100;
        margin: 0;

        @include media-breakpoint-up(md) {
          font-size: 20px;
          text-align: left;
          letter-spacing: -.23px;
          line-height: 32px;
          margin-bottom: -3px;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: 3px;
        }
      }
    }
  }


  .ma3_post-editorial-links {
    color: $white;

    .item {
      border-bottom: 1px solid $white;
      @include media-breakpoint-up(md) {
        border-bottom: 0;
      }
    }

    .item-wrapper {

      @include media-breakpoint-up(md) {
        &:nth-child(2) {
          border-left: 1px solid $white !important;
          border-right: 1px solid $white !important;
        }
      }
    }
  }

  .cta-partial {
    color: $white;
    margin-bottom: 60px;
    margin-top: 10px;
    @include media-breakpoint-up(md) {
      margin-top: 120px;
      margin-bottom: 140px;
    }
  }

  .owl-carousel {
    .owl-dot {
      background-color: $white !important;
    }
  }

  .m21_contact-form {
    color: $white !important;
    padding-top: 0;

    .header {
      border-bottom-color: white;

      .back {
        background-image: url(/img/icons/carousel-inactive-arrow-white.svg);

      }
    }

    .form-input-wrapper {
      border-bottom: 1px solid $white;
    }

    .single-select {

      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $white !important;

      &:after {
        background-image: url(/img/icons/arrow-white.svg);
      }
    }

    input {
      color: $white;
    }

    .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
      color: $white;
    }

    .next-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      flex-direction: row;
      width: 100%;

      .next {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:after {
          background-image: url(/img/icons/arrow-white.svg);
        }
      }
    }

    .dropdown-toggle {
      outline: none !important;
      box-shadow: none !important;

      &:after {
        background-image: url("/img/icons/caret-down.svg") !important;
      }
    }

    input, textarea, .label, .terms-container a {
      border-color: $white !important;
      color: $white !important;
    }

    .dropdown-menu.show {
      background: black;
      color: white;
      border-radius: 0;
      font-weight: 100;
      margin-top: 15px;
      padding-top: 0;
      padding-bottom: 0;
      border: 0;

      a {
        font-weight: 100;
      }

      a:hover, .selected {
        background: $citiblue;
      }
    }

  }
}

