.m7_qa {

  background: $ltgrey;

  @include media-breakpoint-up(lg){
    .collapse {
      display: block !important;
      height: auto !important;
    }
    .collapsing {
      height: auto !important;
      display: block !important;
    }

    .answer {
      position: static !important;
    }

  }

  .qa-question-row:last-of-type .qa-question-wrapper {
    border-bottom: 1px solid rgba(0,0,0,0.3);
  }
  .qa-question-wrapper:first-of-type{
  }
  .qa-question-wrapper {
    border-top: 1px solid rgba(0,0,0,0.3);
    //padding: 18px 0px;

    @include media-breakpoint-up(lg){
      padding: 0px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    .question {
      font-family: "saintecolombe";
      font-weight: 100;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.2px;
      margin-bottom: 10px;
      //font-style: italic;
      //background: red;
      padding-top: 18px;
      padding-bottom: 8px;
      cursor: pointer;
      position: relative;

      span {
        max-width: 90%;
        display: block;
      }

      .chevron {
        display: block;
        position: absolute;
        right: 20px;
        top: 12px;

        @include media-breakpoint-up(lg){
          display: none;
        }

        svg {

          $trans: all 0.2s;
          transition: $trans;
          transform: rotate(180deg);
        }
      }

      &[aria-expanded=true] {
        .chevron {
          svg {
            transform: rotate(0deg);
          }
        }
      }

      @include media-breakpoint-up(lg){
        max-width: none;
        width: 33.3%;
        margin-right: auto;
        padding: 20px 0px;

        line-height: 50px;
        font-size: 34px;
        font-style: normal;
      }
    }

    .answer {

      display: block;
      max-height: 0;
      overflow: hidden;
      padding-bottom: 0;
      transition: .4s ease-in-out;
      width: 100%;

      &.show {
        transition: .4s ease-in-out;
        max-height: 700px;
        padding-bottom: 20px;
      }

      @include media-breakpoint-up(lg) {
        overflow: unset;
        max-height: unset;
        padding-bottom: 20px;
      }


        .text-container {
         p, .learn-more {
           font-family: Interstate, Sans-Serif;
           font-size: 16px;
           line-height: 24px;
           font-weight: 100;
         }
      }


      @include media-breakpoint-up(lg){
        width: 35%;
        margin-left: auto;
        margin-right: 0px;
        padding: 20px 0px;
      }

      .anim-wrapper {
        @include media-breakpoint-up(lg){
          $trans: all 1.2s;
          transition: $trans;
          transform: translateX(100px);
          flex-direction: column;
          display: flex;
          justify-content: center;
        }
      }
    }



    @include media-breakpoint-up(lg){
      .answer .anim-wrapper, .image-wrapper {
        opacity: 0;
      }

      .image-wrapper {
        transform: translate( -20%, -50%);
      }
      &:hover {
        .answer .anim-wrapper, .image-wrapper {
          opacity: 1;
        }

        .image-wrapper {
          transform: translate( -50%, -50%);
        }

        .answer .anim-wrapper {
          transform: translateX(0px);
        }

        .question {
          font-style: italic;
        }
      }
    }


  }



  .image-wrapper {
    position: relative;
    width: 100%;
    //text-align: center;
    pointer-events: none;
    margin-bottom: 15px;
    img {
      width: 100%;
    }


    @include media-breakpoint-up(lg){

      $trans: all 1.2s ease-out;
      transition: $trans;
      position: absolute;
      z-index: 2;
      left: 46%;
      top: 50%;
      width: 25.5%;
      transform: translate( -50%, -50%);


      &.stagger {
        left:  50%;
      }
    }
  }



  &.cappuchino {
    background: $cappuccino;

    .question {

      @include media-breakpoint-up(lg) {
        width: 33.3%;
        span {
          max-width: 100%;
        }
      }
    }

    .answer {
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
    }

  }

  //.answer {
  //  display: block;
  //  max-height: 0;
  //  overflow: hidden;
  //  padding: 0;
  //  transition: .4s ease-in-out;
  //  width: 100%;
  //
  //  &.show {
  //    transition: .4s ease-in-out;
  //    max-height: 700px;
  //  }
  //}
}



//@media (min-width: 768px) {
//  .collapse.dont-collapse-sm {
//    display: block;
//    height: auto !important;
//    visibility: visible !important;
//  }
//}
