.ma21c_article-header {
  padding-bottom: 0 !important;
  &.standard-module {
    padding-top: 30px;
    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
      padding-top: 100px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 40px;
      padding-top: 60px;
    }
  }


  .container {
    position: relative;
  }
  .article-details {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 65px;
      flex-direction: row;
    }

    .date-time {
      margin-bottom: 0 !important;
      margin-left: 20px;
      margin-top: 2px;
      display: none;

      @include media-breakpoint-up(md) {
        display: block !important;
        margin-left: 0;
        margin-top: 20px;
      }

      @include media-breakpoint-up(lg) {
        display: block !important;
        margin-top: 20px;
        margin-left: 0;
      }

      @include media-breakpoint-up(xl) {
        margin-left: 20px;
        margin-top: 0;
      }

      .date, .time {
        display: inline-block;
      }

      .time {
        &:before {
          display: inline-block;
          content: "•";
          margin-left: 5px;
          margin-right: 5px;
        }
      }

    }
  }


  .text-container {
    padding-bottom: 20px;
    @include media-breakpoint-up(md) {
    }
    @include media-breakpoint-up(lg) {
      padding-top: 60px;
    }


    .label {
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    h1 {
      margin-bottom: 16px;

      font-size: 36px;
      line-height: 50px;
      @include media-breakpoint-up(md) {
        font-size: 46px;
        line-height: 60px;
        margin-bottom: 20px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 56px;
        line-height: 65px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 100;
      text-align: left;
      line-height: 24px;
      @include media-breakpoint-up(md) {
        font-size: 18px;
        text-align: left;
        width: 90%;
        letter-spacing: -0.01em;
        line-height: 1.5em;
      }
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .date-time {
      font-size: 16px;
      line-height: 25px;
      color: $darkGrey;
      font-weight: 100;
      margin-bottom: 24px;
      @include media-breakpoint-up(md) {
        display: none;
      }

      .date, .time {
        display: inline-block;
      }
      .time {
        &:before {
          display: inline-block;
          content: "•";
          margin-left: 5px;
          margin-right: 5px;
        }
      }

    }

    .authors {
      text-transform: uppercase;
      font-size: 12px;
      font-family: Interstate;
      line-height: 1.5em;

      .author-wrapper {
        margin-bottom: 15px;
      }

      .name {
        margin-bottom: 1px;
        @include media-breakpoint-up(md) {
          display: inline;
        }
      }

      .role {
        font-weight: 100;

        @include media-breakpoint-up(md) {
          display: inline;
          &:before {
            content: ", ";
            font-weight: normal;
          }
        }
      }

    }

  }


  .hero-image-container {
    height: 100%;
    overflow: hidden;
    padding-bottom: 115%;
    position: relative;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 80%;
      overflow: hidden;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';


      @include media-breakpoint-up(md) {
        position: relative;
      }
      @include media-breakpoint-up(lg) {
        position: absolute;
      }
    }
  }

  &.no-hero {



    .hero-image-container {
      display: none;
    }

    .row {
      align-items: center;
      justify-content: center;
    }

    .text-container {
      text-align: center !important;
      padding-top: 60px;
      padding-bottom: 50px;
      h1 {
        margin-bottom: 34px;
      }

      @include media-breakpoint-up(md) {
        padding-top: 96px;
      }
    }

    .article-details {
      justify-content: center;
      margin-bottom: 48px;
      .label {
        margin-bottom: 0;
      }
      .date-time {
        display: none !important;
      }
    }

    .author-wrapper {

      margin-bottom: 20px !important;

      .name {
        font-size: 16px;
        line-height: 25px;
        &:before {
          content: "by:";
          margin-right: 2px;
        }
        &:after {
          content: ",";
          margin-left: -2px;
          margin-right: 2px;
        }
      }

      .role {
        font-size: 16px;
        line-height: 25px;
        &:before {
          content: ""
        }
      }
    }

    .date-time {
      display: block !important;
    }

    .reverse-wrapper {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.breadcrumb-container + .ma21c_article-header {
  @include media-breakpoint-up(md) {
    margin-top: -30px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -40px;
  }
}
