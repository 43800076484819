.ma3_article {
  .body-text {
    margin-bottom: 70px;
    @include media-breakpoint-up(md) {
      margin-bottom: 90px;
    }
  }

  .people, .people-multi {
    .person {
      background: $ltgrey;
    }

    .headshot {
      background: $ltgrey !important;
    }
  }
}
