.m11b_offerings-details-wrapper {
  .services-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background-color: #F2F6F7;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;

    @include media-breakpoint-up(md) {
      padding-top: 30px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 70px;
    }
  }

  .filter-options {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    a {
      padding-left: 15px;
      padding-right: 15px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 16px;
      color: $darkGrey;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        margin-right: 10px;
      }

      @include media-breakpoint-up(lg) {
        border-bottom: 0;
      }

      &.active {
        color: $citiblue !important;
      }

      &:hover {
        color: $citiblue !important;
      }
    }
  }

  .bar-container {
    margin-top: 15px;
    position: relative;
    display: block;
    width: calc(100% - 2px);
    @include media-breakpoint-up(lg) {
    }
  }

  .bottom-bar {
    height: 1px;
    background-color: $citiblue;
    width: 100%;
  }

  .active-bar {
    position: absolute;
    height: 7px;
    width: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: $citiblue;
    transition: all .5s ease 0s;
  }


  .standard-module {
    padding-top: 0px !important;
  }
}

