.m21_contact-form, .m21_contact-form-modal-inner {
  padding-top: 100px;
  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    //padding-top: 200px;
  }

  .title-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      margin-bottom: 0;
      margin-top: 50px;

    }
  }

  .title-container {
    text-align: center;
    max-width: 340px;
    @include media-breakpoint-up(lg) {
      text-align: left;
      max-width: 300px;
    }
    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        font-size: 45px;
        line-height: 55px;
      }
    }

    p {
      font-family: Interstate;
      font-size: 16px;
      line-height: 26px;
      font-weight: 100;
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 2px solid $black;
    padding-bottom: 10px;


    @include media-breakpoint-up(md) {
      min-height: unset;
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 15px;
    }

    .back {
      height: 30px;
      width: 30px;
      background-image: url("/img/icons/carousel-inactive-arrow.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 3px;
      margin-right: 20px;
      @include media-breakpoint-up(md) {
        margin-bottom: 20px;
      }
    }

    .title {
      font-family: saintecolombe;
      font-size: 22px;
      line-height: 32px;
      //flex: 1;
    }
  }

  .item {
    display: flex;
    //justify-content: center;
    flex-direction: column;
    min-height: 250px;
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
  }

  select {
    overflow: auto;
    border: 0;
    background: transparent;
  }

  /* Feel free to change duration  */
  .animated {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .form-container {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }


  .form-input-wrapper {
    width: 100%;
    border-bottom: 1px solid $black;
    @include media-breakpoint-up(md) {
      width: 49%;
    }
  }

  .link-body {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: Interstate;
    font-size: 16px;
    font-weight: 100;
    line-height: 28px;
  }

  .single-select, .region-states {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    outline: none;
    cursor: pointer;
    font-family: Interstate;
    font-weight: 100;
  }

  .single-select {

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: "";
      width: 15px;
      height: 12px;
      background-image: url(/img/icons/arrow-black.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      margin-top: -4px;
      margin-left: 5px;
    }
  }

  .region-states {
    background: transparent;
    border: 0;
    width: 100%;
  }

  .next-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    flex-direction: row;
    width: 100%;

    .next {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:after {
        content: "";
        width: 15px;
        height: 12px;
        background-image: url(/img/icons/arrow-black.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: -4px;
        margin-left: 5px;
      }
    }
  }

  .contact-info {
    .form-container {
      display: block;
    }
  }

  .thank-you {
    padding-top: 20px;
    h1 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 100;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      font-weight: 100;
    }
  }

  .contact-input-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;

    .label, .error-msg {
      padding: 0;
      font-size: 10px;
      background: transparent;
      margin-bottom: -5px;
      font-weight: normal;
    }

    .error-msg {
      display: none;
      color: red;
    }

    input {
      border: 1px solid $black;
      width: calc(100% - 1px);
      background: transparent;
      height: 50px;
      padding-left: 10px;
      border-radius: 0;
      padding-right: 10px;
      font-weight: 100;
      -webkit-appearance: none;
      -moz-appearance: none;


      &:focus {
        outline: 1px solid $citiblue !important;
        border: 1px solid $citiblue !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -webkit-appearance: none;
        box-shadow: none;
        outline-offset: 0;
      }
    }

    textarea {
      min-height: 225px;
      width: calc(100% - 1px);
      border: 1px solid $black;
      background: transparent;
      resize: none;
      padding-left: 10px;
      padding-right: 10px;
      font-weight: 100;
      border-radius: 0;

      &:focus {
        outline: 1px solid $citiblue !important;
        border: 1px solid $citiblue !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline-offset: 0;
      }
    }

    &.error {
      input, textarea {
        border: 1.5px red solid !important;
      }

      .error-msg {
        display: block;
      }
    }

  }

  .legal-note {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;

    p {
      font-family: Interstate;
      line-height: 18px;
      font-size: 12px;
      font-weight: 100;
      margin-left: 10px;
    }
  }

  .foot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .submit-container, .terms-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .submit-container {
    margin-bottom: 35px;
    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  .terms-container {
    @include media-breakpoint-up(md) {
      margin-left: 20px;
    }
    a {
      text-decoration: underline;
      font-size: 12px;
      line-height: 18px;
      font-weight: 100;
    }
  }

  .bootstrap-select {
    width: 100% !important;
    padding-top: 15px;
    padding-bottom: 15px;

    button {
      background: transparent !important;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }

    .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
      color: #212529;
      background-color: transparent !important;
      border: 0;
    }

    .btn:focus {
      outline: none !important;
    }

    .dropdown-toggle {
      outline: none !important;
      box-shadow: none !important;

      &:after {
        border: 0;
        height: 8px;
        width: 15px;
        background-image: url("/img/icons/caret-down-black.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        animation: all ease-in-out 200ms;
      }
    }

    &.show {
      .dropdown-toggle::after {
        transform: rotate(180deg);
        animation: all ease-in-out 200ms;

      }
    }
  }

  /* Customize the label (the container) */
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: -4px;


    border-bottom: 1px solid #696565;
    padding-bottom: 13px;
    padding-top: 4px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 2px;
    }
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 1px;
    height: 20px;
    width: 20px;
    background-color: #ccc;
    border: 1px solid white;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: $black;
    border: 1px solid $black;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    border: solid $white !important;
    border-width: 0 3px 3px 0 !important;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 6px;
    height: 13px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .dropdown-menu.show {
    border-radius: 0;
    font-weight: 100;
    margin-top: 14px;
    padding-top: 0;
    padding-bottom: 0;
    border: 0;

    a {
      font-weight: 100;
    }

    a:hover, .selected {
      background: $citiblue;
      color: $white;
    }
  }
}
