.m21_contact-form-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: $zIndexNavbar + 1;
  background: $ltgrey;
  top: 100vh;
  transition: all .3s ease-in-out;
  opacity: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;

  &.open {
    top: 0;
    opacity: 100;
  }

  .close-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 25px;

    img {
      width: 38px;
      height: 38px;
    }
  }

  .text-container {
    overflow: scroll;
    height: 100%;
    padding-left: 40px;
    padding-right: 65px;
    font-weight: 100;
  }
}
