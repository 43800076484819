.m12_cpb-people {
  overflow: hidden;

  .people-container {
    width: 100%;
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .carousel-container {
    border-top: 1px solid rgba(0, 0, 0, .3);
    @include media-breakpoint-up(md) {
      border: 0;
      padding-right: 0;
    }
  }

  .person {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    &:hover {

      @include media-breakpoint-up(lg) {
        .expand-container {
          opacity: 100;
        }
      }
    }

    .headshot {
      width: 200px;
      //height: 200px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;

      img {
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .name {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 10px;
      width: 100%;
    }

    .role, .location {
      font-size: 14px;
      font-weight: 100;
      line-height: 24px;
      width: 100%;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .label {
      position: absolute;
      bottom: 0px;
    }

    .text-container {
      padding: 0;
      align-items: center;

      .expand-container {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        opacity: 100;
      }
    }

    .text-container > .quote {
      margin-top: 24px;
    }

    .quote {
      font-family: saintecolombe;
      font-weight: 100;
      font-size: 14px;
      display: none;

      &:before {
        content: '';
        display: block;
        background-image: url("/img/icons/single-quote-left.svg");
        background-repeat: no-repeat;
        width: 100%;
        height: 18px;
        background-position: center;
        border: 0;
        background-size: 18px;
        margin-bottom: 12px;
      }

      &:after {
        content: '';
        display: block;
        background-image: url("/img/icons/single-quote-right.svg");
        background-repeat: no-repeat;
        width: 100%;
        height: 18px;
        background-position: center;
        border: 0;
        background-size: 18px;
        margin-top: 12px;
      }
    }

    .expand-container {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      opacity: 100;
      z-index: 1;
    }

    &.main {

      //position: absolute;
      //top: 50%;
      //transform: translateY(-50%);
      padding-top: 40px;
      padding-bottom: 40px;

      margin: 0;

      .quote {
        display: block;
      }

      .text-container {
        margin-left: 0;
      }

      .headshot {
        height: 232px;
        width: 232px;
      }

      .role-location-container {
        .role {
          display: inline;

          &:after {
            content: ", ";
            margin-right: 3px;
          }
        }

        .location {
          display: inline;
        }
      }

      .expand-container {
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;

        img {
        }
      }

      .learn-more {
        display: none;
      }
    }

    &.vertical {
      //border-bottom: 1px solid rgba(0, 0, 0, .3);

      @include media-breakpoint-up(md) {
        border: 0;
      }

      .text-container {
        @include media-breakpoint-up(md) {
          text-align: left;
          max-width: 190px;
        }
      }
    }


    &.horizontal {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, .3);
      padding-bottom: 30px;
      padding-top: 30px;
      margin: 0;
      flex: 1;
      align-items: center;

      &:last-child {
        border: 0 !important;
      }

      .name {
        font-size: 18px;
      }

      .role {
        font-size: 14px;
      }

      .headshot {
        width: 50%;
        height: 132px;
        display: flex;
        flex-direction: row;
        margin-bottom: 0;

        img {
          width: 140px;
        }

      }

      .text-container {
        align-items: flex-start;
      }

      .quote {
        display: none;
      }

      .text-container {
        width: 50%;
        //margin-left: 5px;
      }

      &:nth-child(3) {
        border-bottom: 0;
      }

      .text-container {
        .expand-container {
          display: block;
          position: relative;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .owl-carousel .owl-stage-outer {
    border-bottom: 1px solid rgba(0, 0, 0, .3);;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      border: 0;
      margin: 0;
    }
  }

  &.two-column, &.three-column {
    .owl-item {
      .person {
        border-right: 1px solid rgba(0, 0, 0, .3);
      }

      &:last-child {
        .person {
          border-right: 0;
        }
      }
    }

    .owl-carousel .owl-stage-outer {
      margin-left: 1px;
      margin-right: 1px;
    }

    .headshot {
      height: 200px;
    }

    .text-container {
      margin-left: 0;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      width: auto;
    }
  }

  &.two-column {

    .owl-item {
      .person {
        flex-direction: row;


        border-right: 1px solid rgba(0, 0, 0, .3);

        .text-container {
          text-align: left;
          padding-left: 40px;
          padding-right: 20px;
        }

        .headshot {
          height: 200px;
          width: 200px;
        }
      }


      &:last-child {
        .person {
          border: 0 !important;
        }
      }
    }
  }

  &.three-column {
    .person {
      padding-left: 10px;
      padding-right: 10px;
    }
  }


  .main-person {
    display: none;
    margin-top: 75px;
    margin-bottom: 90px;

    @include media-breakpoint-up(md) {
      display: block;
    }

    .person {
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      margin: 0;
    }

    .headshot {
      height: 250px !important;
      width: 250px !important;
    }

    .text-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 0;
      margin-top: 20px;
      text-align: left;
    }

    .label {
      position: static;
      margin-bottom: 15px;
    }

    .name {
      font-size: 24px;
      line-height: 30px;
    }

    .role, .location {
      font-size: 20px;
      font-weight: 100;
      line-height: 28px;
    }

    .description-container {
      margin-top: 20px;

      p {
        font-size: 16px;
        line-height: 26px;
        font-weight: 100;
      }
    }
  }

  .owl-carousel .owl-stage {
    display: flex;
  }

  .item {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
  }

  .owl-dots {
    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }
  }

  .owl-nav {
    position: absolute;
    bottom: -37px;
    right: 0;
    width: unset;
    align-content: flex-end;
    justify-content: flex-end;
    top: auto;

    .owl-prev {
      margin-left: 0;
      margin-right: 25px;
    }

    .owl-next {
      margin: 0;
    }
  }
}

.m12_cpb-people-description-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: $zIndexNavbar + 1;
  background: rgba(0,0,0,.3);;
  top: 100vh;
  transition: all .3s ease-in-out;
  opacity: 0;
  display: flex;
  flex-direction: column;

  >.container {

    background: $white;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

  }

  &.open {
    top: 0;
    opacity: 100;
  }

  .close-container {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 25px;


    @include media-breakpoint-up(md) {
      right: 40px;
      top: 35px;

    }
    img {
      width: 38px;
      height: 38px;
    }
  }

  .main-person {
    margin-top: 105px;
    margin-bottom: 90px;
    padding: 0px 35px;

    @include media-breakpoint-up(md) {
      display: block;
      padding: 0px 50px;
    }

    .person {
      justify-content: flex-start;
      flex-direction: row;
      align-items: flex-start;
      padding: 0;
      margin: 0;
    }

    .headshot {
      height: 190px !important;
      width: 217px !important;
      background: $white;

      img {
        object-fit: cover;
        font-family: 'object-fit: contain;';
      }

    }

    .text-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 0;
      margin-top: 20px;
      text-align: left;
    }

    .label {
      position: static;
      margin-bottom: 15px;
    }

    .name {
      font-size: 24px;
      line-height: 30px;
    }

    .role, .location {
      font-size: 20px;
      font-weight: 100;
      line-height: 28px;
    }

    .description-container {
      margin-top: 20px;

      p {
        font-size: 16px;
        line-height: 26px;
        font-weight: 100;
      }
    }
  }
}
