.m5b_resources {

  .module-header {
    border-bottom: 0.5px solid $black;

    @include media-breakpoint-up(md) {
      border: 0;
    }

  }

  .citi-card {
    border-bottom: 0.5px solid $black;

    .card-item-description {
      height: unset;
    }

    .card-footer-body {
      height: unset;
    }
  }
}
