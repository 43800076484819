.m1ab_header + .ambitions-partial {
  @include media-breakpoint-up(md) {
    margin-top: -20px;
  }
  @include media-breakpoint-up(md) {
    margin-top: -40px;
  }
}

.ambitions-partial {

  @include media-breakpoint-down(sm) {
    background: $white;
  }


  .container {

    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
      padding-bottom: 100px;
    }
  }

  .module-header {

    @include media-breakpoint-up(md) {
      padding-top: 10px;
      padding-bottom: 50px;
    }
    p {
      @include media-breakpoint-up(md) {
        padding-bottom: 0px;
      }
    }
  }

  .text-container {

  }

  .cards-container {
    //margin-top: 35px;
  }

  .citi-card {

    @include media-breakpoint-up(md) {
      padding-left: 21px !important;
      padding-right: 21px !important;
    }
  }

  .cards-container .row .ambitions-card-container:last-of-type{
    .citi-card {
      border-left: 0;
      border-right: 0;
    }
  }
  @include media-breakpoint-up(md) {
    .cards-container .row .ambitions-card-container:nth-child(odd){
      .citi-card {
        border-right: 0.5px solid $black;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .cards-container .row .ambitions-card-container:nth-child(odd){
      .citi-card {
        border-right: 0;
      }
    }

    .cards-container .row .col-lg-4:nth-child(3n - 1){
      .citi-card {
        border-left: 0.5px solid $black;
        border-right: 0.5px solid $black;
      }
    }
  }


  a.ambitions-card-container {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }




  .card-footer-body {
    @include media-breakpoint-down(sm) {
      padding-bottom: 5px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 20px;
    }
  }

  .card-cta-item {
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 35px;
    padding-right: 35px;
    height: 100%;
    width: 100%;
    background-image: url("/img/ambitions/cta-background.png");

    .card-item-title {
      font-family: saintecolombe;
      font-size: 24px;
      line-height: 33px;
      width: 100%;

      @include media-breakpoint-up(md) {
        font-size: 30px !important;
      }
      @include media-breakpoint-up(xl) {
        font-size: 36px !important;
      }
    }

    .card-item-description {
      margin-top: 18px;
      line-height:22px;
      font-weight: 100;
      width: 100%;

      @include media-breakpoint-up(md) {
        line-height: 1.5em;
      }
      @include media-breakpoint-up(md) {
        margin-top: 15px;
      }
    }
    .background {
      position: absolute;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: 0;


      img {
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .btn {
      margin-top: 22px;
      @include media-breakpoint-up(md) {
        margin-top: 32px;
      }
      @include media-breakpoint-up(lg) {
        margin-top: 38px;
      }
    }
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  .card-cta-container {
    //clip-path: inset(20px 20px 60px);
    background-repeat: no-repeat;
    background-size: cover;

    .card-item-title {
      font-family: saintecolombe;
      font-size: 24px;
      font-weight: 100;
      margin-top: 0px;
      z-index: 1;
      line-height: 1.5em;
      @include media-breakpoint-up(md) {
        font-size: 36px;
        font-weight: 100;
        margin-top: 0px;
        z-index: 1;
        line-height: 1.25em;
      }
    }

    @include media-breakpoint-up(md) {

      padding: 20px 20px 50px;
    }
  }
}


a.ambitions-card-container {

  .citi-card-inner-wrapper {
    transition: all 0.2s ease-in-out;
  }

  .learn-more {
    display: block;
  }

  @include media-breakpoint-up(md) {
    padding: 0;
  }
  &:hover {
    color: $black;
    .citi-card-inner-wrapper {
      transition: all 0.2s ease-out;

      transform: translateY(-20px);
    }
    .learn-more {
      opacity: 100;
    }
  }
}


.ambitions-card-container {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //text-align: center;

  &:hover {

    .learn-more {
      opacity: 100;
    }
  }

  @include media-breakpoint-up(md) {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .image-container {
    height: 152px;
    @include media-breakpoint-up(md) {
      height: 170px;
    }


    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain;';
    }
  }

  .label {
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    background: $aqua;
    min-height: 24px;
    font-size: 12px;
    letter-spacing: -.23px;
    color: $black;
    line-height: unset;
    padding-top: 3px;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      font-size: 14px;

    }
  }

  .title {
    font-family: saintecolombe;
    font-size: 20px;
    line-height: 24px;
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      font-size: 24px;
      line-height: 32px;
      margin-top: 30px;

    }
  }

  .body {
    font-family: Interstate;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    margin-top: 15px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 26px;
      margin-top: 30px;
    }
  }

  .learn-more {
    margin-top: 10px;
    color: $citiblue;
    font-size: 14px;
    font-weight: 100;
    transition: all 0.2s ease-out;

    @include media-breakpoint-up(md) {
      opacity: 0;
    }

    .arrow {
      margin-left: 6px;
      position: relative;
      top: -1px;
      width: 13px;
      $trans: all 0.2s ease-out;
      -webkit-transition: $trans;
      -moz-transition: $trans ;
      -ms-transition: $trans ;
      -o-transition: $trans ;
      transition: $trans ;
    }
  }
}
