.m13-insights {


  .insight-masonry-wrapper-active .masonry-item:nth-of-type(2) .masonry-item-inner {
    padding-top: 100px;
    display: block;
  }


  .masonry-item {
    overflow: hidden;
  }
  .citi-card {
    border-bottom: none !important;
  }

  .insights-carousel-wrapper {
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      overflow: visible;
    }
  }

  .insights-carousel.insights-carousel-active {
    margin-left: -20px;
    margin-right: -20px;

  }

  .owl-stage-outer {
    margin-bottom: 10px;
  }

  .insight-masonry-wrapper.insight-masonry-wrapper-active {

    .masonry-item {
      overflow: visible;
    }

    .scroller {
      height: 100vh;
      padding-top: 10px;
      //overflow-y: scroll;
      position:relative;

      // Hide scroll bar
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .citi-card {
      padding-left: 0px;
      padding-right: 0px;
    }

    //&::after, &::before {
    //  display: block;
    //  content: "";
    //  width: 100%;
    //  height: 10px;
    //  position: absolute;
    //  left: 0px;
    //  z-index: 2;
    //}
    //&::after {
    //  top: 0px;
    //  background-image: linear-gradient(to bottom, rgba(242, 246, 247,1), rgba(242, 246, 247,0));
    //}
    //
    //&::before {
    //  bottom: 0px;
    //  background-image: linear-gradient(to bottom, rgba(242, 246, 247,0), rgba(242, 246, 247,1));
    //}
  }

  .header-container {


    @include media-breakpoint-up(md) {
      padding-left: 70px;
      flex-direction: row;
      display: flex;
    }

    @include media-breakpoint-up(lg) {

      height: 100vh;
    }
  }

  .citi-card {
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-top: 0px;

    .card-footer-body {
      height: 280px;
      @include media-breakpoint-up(md) {
        height: unset;
      }
    }
  }


  a.masonry-item-inner {
    @include media-breakpoint-up(md) {
      padding: 0;
    }
    &:hover {
      color: $black;
    }
  }

  .module-header {
    @include media-breakpoint-up(md) {
      align-items: flex-start;
      h1 {
        font-size: 90px;
        text-align: left;
        line-height: 100px;
      }

      p {
        text-align: left;
      }

      .learn-more {
        text-align: left;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }


  //nested inside citi-card
  .citi-card:hover .citi-card-inner-wrapper.hover-slide-up  {
      @include media-breakpoint-up(md) {
          transform: translateY(-10px) !important;
      }
  }

}
