.m1ab_header + .standard-module.overlappable {
  > .container {
    @include media-breakpoint-up(lg) {
      padding-top: 50px;
    }
  }
}

.m10_cards-with-filter {
  .container {
    padding-top: 70px;
    @include media-breakpoint-up(md) {
      padding: 60px 65px 70px;

    }
  }

  .module-header {
    @include media-breakpoint-up(lg) {
      padding-bottom: 60px;
    }
  }

  .grid-item {
    //min-height: 200px !important;;
    //width: 200px !important;
    flex: 1;
    display: block !important;
    visibility: visible !important;
  }


  .services-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    //padding-bottom: 26px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: $white;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 50px;
    }
  }

  .filter-options {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 75%;
    }

    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    .link-wrapper {
      width: 33%;

      @include media-breakpoint-up(lg) {
        width: 20%;
      }
    }

    a {
      margin-bottom: 27px;
      padding-left: 15px;
      padding-right: 15px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      border-bottom: 5px solid transparent;
      letter-spacing: .23px;
      font-weight: 100;
      color: $darkGrey;

      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
      }

      @include media-breakpoint-up(lg) {
        border-bottom: 0;
        margin: 0;
      }

      &.active {
        color: $citiblue !important;

        border-bottom: 5px solid $citiblue;

        @include media-breakpoint-up(lg) {
          border-bottom: 5px solid transparent;
        }
      }

      &:hover {
        color: $citiblue !important;
      }
    }
  }

  .bar-container {
    display: none;
    margin-top: 15px;
    position: relative;


    @include media-breakpoint-up(lg) {
      display: block;
      width: 75%;
    }

    @include media-breakpoint-up(xl) {
      display: block;
      width: 60%;
    }
  }

  .bottom-bar {
    height: 1px;
    background-color: $citiblue;
    width: 100%;
  }

  .active-bar {
    position: absolute;
    height: 7px;
    width: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: $citiblue;
    transition: all .5s ease 0s;
  }

  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

  .main-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }

    .text-container {

      @include media-breakpoint-up(md) {
        max-width: 490px;
      }
    }

    h1 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        font-size: 45px;
        line-height: 55px;
        margin-bottom: 32px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 100;
      margin-bottom: 50px;
      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 70px;
      }
    }

  }

  .sub-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }

    .title {
      font-family: saintecolombe;
      text-align: center;
      margin: 0;
      font-size: 22px;
      @include media-breakpoint-up(md) {
        max-width: 590px;
        font-size: 26px;
        line-height: 40px;
      }
    }
  }

  .cta-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ECECE3;
    padding-left: 35px;
    padding-right: 35px;
    height: 100%;
    margin-top: 20px;
    width: 100%;
    @include media-breakpoint-up(md) {
      align-items: flex-start;
      margin-top: 0;
    }

    .body {
      font-family: saintecolombe;
      font-size: 26px;
      margin-bottom: 40px;
      line-height: 40px;
      font-style: italic;
      width: 100%;

    }
  }

  .grid-item-template {
    display: none;
    visibility: hidden;
  }

  .load-more-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
