.breadcrumb-container + .m19-insights-full-masonry {
  @include media-breakpoint-up(md) {
    margin-top: -35px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -25px;
  }
}

.m19-insights-full-masonry {
  background: #F2F6F7;

  @include media-breakpoint-up(md) {
    //padding-top: 60px;
    //padding-left: 65px;
    //padding-right: 65px;
    //padding-bottom: 70px;
    padding: 50px 0px 70px;

  }
  .module-header {
    padding-bottom: 35px;
    @include media-breakpoint-up(md) {
      padding-bottom: 20px;
    }
  }

  .m19-insights-full-masonry-inner-wrapper {

    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }

    .in-grid-page-header {
      .module-header {
        display: block !important;
        padding: 100px 0px;
        h1 {
          font-size: 80px;
          line-height: 90px;
          text-align: left;
          font-weight: 100;
          width:100%;
          overflow-wrap: break-word;

        }
        p {
          text-align: left;
          line-height: 35px;

        }
      }
    }

    .in-grid-cta {
      padding-top: 20px;
      .inner {
        padding-top: 15px;
        background-color: #ECECE3;
        display: flex !important;
        flex-direction: column;
        align-items: center;
      }
      img {
        width: 80%;
        width: calc(100% - 30px);
      }
      .cta-partial {
        margin-top: 70px;
        margin-bottom: 70px;

        p {
          text-align: center;
          line-height: 30px;
          margin-bottom: 20px;

          @include media-breakpoint-up(md) {
            max-width: 250px;
          }

          @include media-breakpoint-up(xl) {
            max-width: 300px;
          }
        }
      }
    }
  }

  .grid-item {
    //width: 30%;

    @include media-breakpoint-up(md) {
      //width: calc(33% - 10px);
    }



    .grid-item-inner {

      .citi-card {
        padding-bottom: 0px;
        margin-bottom: 0px;
        //border: 1px solid red;
      }
    }

  }

}

.insight-image-article-template {
  display: none !important;
}
