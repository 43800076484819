.m4c_client-stories {
  overflow: hidden;
  .citi-card {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 10px;
    @include media-breakpoint-up(md) {
      padding-bottom: 30px;
    }
    .card-item-image {
      @include media-breakpoint-up(md) {
        height: unset;
      }
    }

    .card-media-container {
      //padding-bottom: 56.25%; //For 16:9 aspect ratio
      padding-bottom: 50%;
      overflow: hidden;
      position: relative;
      height: unset;
      img {
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }

    .footer-body-padded {
      //padding: 20px;
      @include media-breakpoint-up(md) {
        padding: 30px;
      }
    }
  }

  .carousel-wrapper {
    margin-left: -20px;
    margin-right: -20px;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }
}
