
.breadcrumb-container {
  z-index: 9998;
  position: relative;
  @include media-breakpoint-up(md) {
    padding-bottom: 20px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 30px;
  }

  &.white {
    color: $white !important;
    a {
      color: $white !important;
      &:hover {
        color: $white !important;
        text-decoration: underline !important;
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      color: $white !important;
    }
  }

  .breadcrumb {
    display: none;
    font-family: interstate, sans-serif;
    font-size: 12px;
    line-height: 20px;
    background-color: transparent;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-top: 45px;


    @include media-breakpoint-up(md) {
      display: block;
      margin-bottom: -100px;
    }


    @include media-breakpoint-up(lg) {
      margin-bottom: -70px;
    }

    .breadcrumb-home {
      //width: 10px;
      padding-right: 0.2rem;
    }
    a {
      &:hover {
        color: $black !important;
        text-decoration: underline !important;
      }
    }
  }

  .breadcrumb-item {
    display: inline-block;
    font-weight: 100;

    + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.2rem;
      content: "/";
      color: $black;
    }

    + .breadcrumb-item {
      padding-left: 0.2rem;
    }

    &:last-of-type {
      font-weight: normal;
    }
  }
}
