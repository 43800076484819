$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1900px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1366px
);

$zIndexNavbar: 99999;
$zIndexSearchContainer: -1;
$zIndexSearchContainerOpen: 2;
$zIndexNavDrawer: 9999;
$zIndexLoginMobile: 100000;
$zIndexLoginDesktop: 99998;

@import "../../node_modules/bootstrap-scss/bootstrap";


@import './fonts';
@import './colors';


.container {
  &.white {
    background: $white;
  }
}

// Hero main tag
h1 {
  font-family: saintecolombe, sans-serif;
  font-weight: 100;
  font-size: 46px;
  //line-height:  1.5em;
  @include media-breakpoint-up(md) {
    font-size: 70px;
    line-height:  65px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 90px;
    line-height:  95px;
  }
}

h2 {
  font-size: 32px;
  font-family: saintecolombe, sans-serif;
  font-weight: bold;
  line-height: 1.5em;
}

h3 {
  font-size: 26px;
  font-family: interstate-regular, sans-serif;
  line-height: 1.5em;
}

h4 {
  font-size: 14px;
  font-family: saintecolombe, sans-serif;
  font-weight: bold;
  line-height: 30px;
  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

h5 {
  font-size: 22px;
  font-family: saintecolombe-medium, sans-serif;
  font-weight: bold;
  line-height: 30px;
}

h6 {
  font-size: 16px;
  font-family: interstate, sans-serif;
  line-height: 1.5em;
  font-weight: normal !important;
}

body {
  font-size: 16px;
  font-family: interstate, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  letter-spacing: -.23px;
}

a {
  color: $black;
  &:hover {
    text-decoration: none;
    color: $black;
  }
}

.hyphenate {
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  $hyphen-min: 15;
  $hyphen-before: 12;
  $hyphen-after: 3;
  -webkit-hyphenate-limit-before: $hyphen-before;
  -webkit-hyphenate-limit-after: $hyphen-after;
  -moz-hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
  -webkit-hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
  -ms-hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
  hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
}

html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  background-color: $ltgrey;
  position: relative;

  // Disable scroll when nav is open
  &.disable-scroll{
    overflow: hidden;
  }
}

.input-group {
  background: #5C5C5C;
  justify-content: center;
  align-items: center;

  .input-group-prepend {
    background: #5C5C5C;
    padding-left: 10px;
  }

  .input-group-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 15px;
    }

    &:after {
      content: "";
      height: 15px;
      width: 2px;
      background: $citiblue;
      margin-left: 10px;
    }
  }

  .clear-password-btn, .clear-search-btn {
    display: none;
    padding-right: 12px;
    cursor: pointer;
    position: absolute;
    right: 0;
    z-index: 999999;

    @include media-breakpoint-up(lg) {
      padding-right: 8px;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }

  .search-input {
    background-image: url("/img/icons/search.svg");
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 17px 17px;
    padding-left: 35px;
  }

  input {
    outline: none !important;
    height: 50px;
    @include media-breakpoint-up(lg) {
      height: 40px;
    }
  }

  ::placeholder {
    font-size: 14px;
    color: $ltgrey;
    font-weight: 100;
  }

  .form-control {
    font-family: interstate, Sans-Serif;
    border-radius: 0;
    background-color: #5C5C5C;
    border: 0;
    color: $white;
    font-size: 12px;

    &.password {
      padding-right: 35px;
      letter-spacing: 3px;
    }

    &.error {
      border: 1.5px red solid;
    }

    &:focus {
      background-color: #5C5C5C;
      color: $white;
      outline: 1px solid #008ce6 !important;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline-offset: 0;
    }
  }
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }
.owl-carousel .owl-item img {
  display: block;
  width: 100%; }
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }
.owl-carousel.owl-loaded {
  display: block; }
.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }
.owl-carousel.owl-hidden {
  opacity: 0; }
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }
.owl-carousel.owl-rtl {
  direction: rtl; }
.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }





@import './buttons';
@import './cards';
@import './pages/*';
@import './partials/*';
