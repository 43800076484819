.m12_cpb-people-global {

  .services-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    //padding-bottom: 26px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 50px;
    }
  }

  .filter-options {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;


    @include media-breakpoint-up(lg) {
      width: 75%;
      justify-content: space-between;
      flex-wrap: nowrap;

    }

    .link-wrapper {
      width: unset;
    }

    a {
      margin-bottom: 32px;
      margin-left: 15px;
      margin-right: 15px;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      border-bottom: 5px solid transparent;
      letter-spacing: .23px;
      font-size: 16px;
      color: #696565;

      @include media-breakpoint-up(md) {
        margin-bottom: 25px;
      }

      @include media-breakpoint-up(lg) {
        border-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0;
      }

      &.active {
        color: $citiblue !important;

        border-bottom: 5px solid $citiblue;

        @include media-breakpoint-up(lg) {
          border-bottom: 5px solid transparent;
        }
      }

      &:hover {
        color: $citiblue !important;
      }
    }
  }

  .bar-container {
    display: none;
    margin-top: 15px;
    position: relative;
    @include media-breakpoint-up(lg) {
      display: block;
      width: 75%;
    }
  }

  .bottom-bar {
    height: 1px;
    background-color: $citiblue;
    width: 100%;
  }

  .active-bar {
    position: absolute;
    height: 7px;
    width: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: $citiblue;
    transition: all .5s ease 0s;
  }

}
