.primary-button {
  font-family: interstate;
  background-color: $black !important;
  width: 186px;
  height: 50px;
  font-size: 18px !important;
  padding-top: 10px !important;
  color: $white !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgb(39, 39, 39) !important;
  }
}


.secondary-button {
  background-color: $white !important;
  width: 100%;
  height: 30px;
  color: $black !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  padding-top: 6px !important;
  border-radius: 0px !important;
}

.tertiary-button {
  background-color: $black !important;
  width: 100%;
  height: 30px;
  color: $white !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  padding-top: 6px !important;
  border-radius: 0px !important;
  border: 1px solid $white;
}

.quaternary-button {
  font-family: interstate;
  background-color: transparent !important;
  width: 186px;
  height: 50px;
  font-size: 18px !important;
  padding-top: 10px !important;
  color: $black !important;
  border-radius: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $black;

  //&:hover {
  //  background-color: rgb(39, 39, 39) !important;
  //}
}
