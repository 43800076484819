.m11a_offerings-details {


  .components-container {
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }


  .layered-images {
    position: relative;
    width: 100%;
    height: 450px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      height: 560px;
      margin-bottom: 100px;
    }

    @include media-breakpoint-up(lg) {
      height: 730px;
      margin-bottom: 100px;
    }

    .image-1 {
      width: 90%;
      height: 75%;

      @include media-breakpoint-up(md) {
        width: 80%;
        height: 80%;
      }
      @include media-breakpoint-up(lg) {
        width: 90%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .image-2 {

      position: absolute ;
      bottom: 0;
      right: 0;
      height: 60%;
      width: 60%;


      @include media-breakpoint-up(md) {
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: 60%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    .image-3 {

      position: absolute ;
      bottom: 15%;
      left: 0;
      width: 50%;

      @include media-breakpoint-up(md) {
        bottom: 0;
        width: 60%;
      }

      @include media-breakpoint-up(lg) {
        bottom: 10%;
      }

      img {
        width: 100%
      }
    }

  }

  .body-text {
    margin-bottom: 50px;
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
      margin-bottom: 70px;
    }

    p {
      font-family: Interstate;
      font-weight: 100;
      font-size: 20px;
      line-height: 32px;
    }

  }

  .quote {
    font-size: 20px;
    text-align: center;
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      text-align: left;
      border-left: 1px solid $black;
      padding-left: 50px;
      margin-bottom: 100px;
    }

    p {
      font-family: saintecolombe;
      line-height: 34px;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
        font-weight: 100;
      }


      &:before, &:after {
        font-family: saintecolombe !important;
        content: '"';
        display: inline;
      }

      @include media-breakpoint-up(md) {
        &:before {
          content: '';
          display: block;
          background-image: url("/img/icons/single-quote-left.svg");
          background-repeat: no-repeat;
          width: 40px;
          height: 40px;
          background-position: 0 -10px;
          border: 0;
          background-size: 36px;
          margin-bottom: 32px;
        }
        &:after {
          content: '';
        }
      }
    }

    .author {
      font-family: saintecolombe;
      font-style: italic;
      font-size: 20px;
      font-weight: 100;

      @include media-breakpoint-up(md) {
        font-family: Interstate;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
      }

      &:before {
        content: '-';
        display: inline;
        @include media-breakpoint-up(md) {
          content: '';
        }
      }
    }


    .author-title {
      display: none;
      font-family: saintecolombe;
      font-size: 20px;
      font-weight: 100;

      @include media-breakpoint-up(md) {
        font-family: Interstate;
        display: block;
        font-size: 16px;
      }
    }
  }

  .single-image {
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      margin-bottom: 100px;
    }

    img {
      width: 100%;
    }
  }

  .right-col {
    @include media-breakpoint-up(md) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .features-title {
    font-size: 28px;
    font-family: saintecolombe;
    font-weight: 100;
    line-height: 34px;
    text-align: center;
    margin-bottom: 30px;


    @include media-breakpoint-up(md) {
      font-size: 45px;
      line-height: 55px;
      margin-bottom: 90px;
    }
  }

  .features-container {
    @include media-breakpoint-up(lg) {

      margin-bottom: 85px;
      .col-md-4:nth-child(1) {
        padding-right: 50px;
      }
      .col-md-4:nth-child(2) {
        padding-left: 32.5px;
        padding-right: 32.5px;
      }
      .col-md-4:nth-child(3) {
        padding-left: 50px;
      }
    }
  }

  .feature-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    text-align: center;
    width: 100%;
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      margin-bottom: 30px;
    }

    .label {
      margin-bottom: 20px;
    }
    .title {
      font-family: saintecolombe;
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 15px;
      width: 100%;
      @include media-breakpoint-up(md) {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 100;
      margin-bottom: 25px;
      width: 100%;
      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 24px;

        margin-bottom: 30px;
      }
    }

    .learn-more {
      font-size: 14px;
      margin: 0;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }
}
