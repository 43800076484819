.ma1_in-page-article {

  position: fixed;
  left: 0;
  //top: 0;
  height: 100%;
  width: 100%;
  z-index: $zIndexNavbar + 1;
  top: 100vh;
  transition: top .3s ease-in-out;

  &.open {
    top: 0;
    .article-close {
      display: block;
      top: 15px;
      @include media-breakpoint-up(lg) {
        top: 40px;
      }
    }

  }

  .content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: scroll;
    position: relative;

    // Hide scroll bar
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }


    @include media-breakpoint-up(lg) {
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      overflow: unset;
    }
  }

  .image-container {
    width: 100%;

    @include media-breakpoint-up(lg) {
      display: flex;
      width: 50%;
    }

    img {
      width:100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }
  }
  .text-container {
    background-color: $white;
    //width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 15px 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-grow: unset;
      width: 50%;
      padding-left: 75px;
      padding-right: 75px;
      padding-top: 40px;
      overflow: scroll;
      height: 100%;
      align-items: flex-start;


      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        display: block;
        .label {
          display: inline-block !important;
        }
        .quote {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }


      // Hide scroll bar
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .label {
      margin-bottom: 15px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        align-self: flex-start;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 250px;
      }
    }

    .title {
      font-size: 30px;
      font-family: saintecolombe;
      line-height: 40px;
      margin-bottom: 16px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        width: 100%
      }

      @include media-breakpoint-up(lg) {
        font-size: 44px;
        line-height: 65px;
        margin-bottom: 14px;
        font-weight: 100;
      }
    }

    .author {
      font-size: 14px;
      font-family: Interstate;
      font-weight: 100;
      line-height: 33px;
      margin-bottom: 30px;
      color: #696565;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        width: 100%
      }

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 33px;
        margin-bottom: 40px;
        color: $black;
      }
    }

    p {
      font-family: saintecolombe;
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 60px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        line-height: 34px;
        margin-bottom: 70px;
        font-weight: 100;
      }
    }

    .quote {
      font-size: 20px;
      font-family: saintecolombe;
      font-weight: bold;
      line-height: 32px;
      margin: 0 25px 60px;
      max-width: 450px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        max-width: 450px;
      }

      @include media-breakpoint-up(lg) {
        text-align: center;
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 70px;
        font-weight: normal;
      }
      &:before {
        content: '';
        display: block;
        background-image: url("/img/icons/single-quote-left.svg");
        background-repeat: no-repeat;
        min-height: 18px;
        background-position: -5px;
        border: 0;
        background-size: 36px;
        margin-bottom: 30px;
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 100%;
          background-position: center;
          background-size: 42px;
          min-height: 30px;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-size: 100%;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        &:after {
          content: '';
          display: block;
          background-image: url("/img/icons/single-quote-right.svg");
          background-repeat: no-repeat;
          width: 100%;
          background-position: center;
          background-size: 42px;
          min-height: 30px;
          margin-top: 30px;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-size: 100%;
          }

        }
      }
    }
  }

  .article-close {
    position: fixed;
    right: 15px;
    cursor: pointer;
    top: 100vh;
    transition: top .3s ease-in-out;
    display: none;

    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 35px;
      top: 40px;
    }

    img {
      width: 28px;
      height: 28px;
    }
  }
}
