
.card-item-image, .card-item-title, .card-item-description, .cta-hover-reveal {
  transition: all .2s ease-in-out;
}


.card-media-container {
  position: relative;
  .tags {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 20px;
    z-index: 1;
  }
}

.card-item-image {
  position: relative;
  width: auto;
  height: 300px;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    width: auto;
    height: auto;
  }
  img {
    //width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    left: 0;
    position: absolute;
    //top: -25%;
    min-width: 100%;
    transition: all .3s ease-in-out;

    @include media-breakpoint-up(md) {
      position: static;
      top: 0;
      transform: translateX(-50%);
      margin-left: 50%;
    }
  }


  @include media-breakpoint-up(md) {
    img {
      height: 100%;
      min-width: 100%;
    }

    //&.media-short img {
    //  height: 250px;
    //}
    //
    //&.media-medium img {
    //  height: 350px;
    //}
    //
    //&.media-tall img {
    //  height: 390px;
    //}
  }
  @include media-breakpoint-up(lg) {
    img {
      height: 310px;
    }

    &.media-short img {
      height: 220px;
    }

    &.media-medium img {
      height: 360px;
    }

    &.media-tall img {
      height: 370px;
    }
  }
  @include media-breakpoint-up(xl) {
    img {
      height: 420px;
    }

    &.media-short img {
      height: 270px;
    }

    &.media-medium img {
      height: 400px;
    }

    &.media-tall img {
      height: 460px;
    }
  }
  @include media-breakpoint-up(xxl) {
    img {
      height: 400px;
    }

    &.media-short img {
      height: 300px;
    }

    &.media-medium img {
      height: 475px;
    }

    &.media-tall img {
      height: 520px;
    }
  }
}


.citi-card-with-hover-effect:hover {
  //if this is a card with a hover effect on it and its a description padded card, we
  // need to add extra padding bottom
  .card-footer-body {
    .learn-more {
      @include media-breakpoint-up(md) {
        height: 10px; //we can add more padding by animating the height of learn-more by 10px from 0 on rolloever. by default, learn-more usually stays 0 height
      }
    }
  }
}

.card-footer-body {
  //padding-top: 10px;

  .card-item-description {
    height: 65px;
    display: block; /* Fallback for non-webkit */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 70px;
    }
  }

  @include media-breakpoint-up(md) {
    height: unset;
  }

  &.footer-body-padded {
    padding: 30px 30px;
    background: white;
    position: relative;
    transition: all .2s ease-in-out;


    @include media-breakpoint-up(md) {
         padding-top: 30px;
         padding-bottom: 30px;
       }
  }

  .learn-more {
    display: block;
    opacity: 100;

    @include media-breakpoint-up(lg) {
      display: none;
      opacity: 0;
    }
  }

  .card-item-title {
    font-size: 22px;
    font-family: saintecolombe;
    font-weight: normal;
    line-height: 30px;
    margin-bottom: 8px;


    display: flex;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    $hyphen-min: 15;
    $hyphen-before: 12;
    $hyphen-after: 3;
    -webkit-hyphenate-limit-before: $hyphen-before;
    -webkit-hyphenate-limit-after: $hyphen-after;
    -moz-hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
    -webkit-hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
    -ms-hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;
    hyphenate-limit-chars: $hyphen-min $hyphen-before $hyphen-after;


    @include media-breakpoint-up(md) {
      font-weight: normal;
      font-size: 26px;
      line-height: 34px;
    }

    &.large {
      font-size: 18px;
      padding-top: 20px;
      margin-bottom: 11px;
      z-index: 1;
      line-height: 1.5em;
      @include media-breakpoint-up(md) {
        font-size: 34px;
        font-weight: 100;
        padding-top: 20px;
        margin-bottom: 15px;
        z-index: 1;
        line-height: 1.3em;
      }
    }
  }


  .card-item-description {
    font-family: Interstate;
    font-size: 14px;
    z-index: 1;
    line-height: 1.5em;
    font-weight: 100;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      font-weight: 100;
      line-height: 1.5em;
    }
  }


  /* applies to learn-more and download classes */
  .cta-hover-reveal {
    //display: none;
    //opacity: 0;
    //height: 0px;

    @include media-breakpoint-up(lg) {
      opacity: 0;
      height: 0px;
      display: block;

      .citi-card:hover & {
        opacity: 100;
        transform: translateY(0px); //fix for render issues on safari
      }
    }
  }


  .download-link {
    display: inline-block;
    align-items: center;
    margin-top: 16px;

    $trans: all 0.3s ease-out;


    &:hover {
      .download-icon {
        transform: scale(1.15);
      }
      .underline {
        transition: all .3s ease-in-out;
        width: 100%;
      }
    }

    .link-text {
      display: inline-block;
      transition: $trans;

      font-size: 14px;
      line-height: 14px;
      color: $citiblue;
      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .download-icon {
      margin-right: 10px;
      display: inline-block;
      transition: $trans;

      img {
        height: 30px;
        width: 30px;
      }
    }

    .link-text-container {
      position: relative;
      top: 1px;
    }

    a {

    }

    .underline {
      position: absolute;
      bottom: 0;
      width: 0;
      height: 1px;
      background: $citiblue;
      left: 0;
      transition: all .3s ease-in-out;
    }
  }


  .byline {
    font-family: interstate;
    font-size: 12px;
    font-weight: 100;
    color: $black;
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #696565;
  }

  .byline-date {
    font-family: interstate;
    font-size: 12px;
    font-weight: 100;
    color: $black;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #696565;
    margin-top: 10px;

    &.chronicle-byline {
      font-size: 14px;
      margin-bottom: 16px;
      padding-bottom: 0px;
      margin-top: 0px;
    }
  }

  .byline + .byline-date {
    margin-top: -15px;
  }



}




a.card-container {
  @include media-breakpoint-up(md) {
    padding: 0;
  }
  &:hover {
    color: $black;
  }
}



.citi-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-top: 20px;
  transition: all .2s ease-in-out;
  width: 100%;
  cursor: pointer;

  .card-container:last-of-type & {
    @include media-breakpoint-down(sm) {
      border-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    border-bottom: 0;
    &.desktop-vertical-divided {
      border-bottom: 0.5px solid $black;
      margin-bottom: 10px;
    }

    &.citi-card-with-hover-effect {

    }
    //
    //padding-left: 21px;
    //padding-right: 21px;
    margin-bottom: 0px;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 45px;
    &.desktop-vertical-divided {
      margin-bottom: 20px;
    }
  }


  .tags {
    .tag {
      font-family: Interstate, Sans-Serif;
      font-size: 14px;
      display: inline-block;
      padding: 4px 12px 0px;
      background-color: #77F9DB;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 100;
      margin-right: 10px;
    }
  }


}
//nested inside citi-card
.citi-card-inner-wrapper {
  .desktop-vertical-divided & {
    padding-bottom: 0px;

    @include media-breakpoint-up(md) {
      padding-bottom: 14px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 16px;
    }
  }

  &.hover-slide-up {
    //position: relative;
    transition: all .2s ease-in-out;
    @include media-breakpoint-up(md) {
      .citi-card:hover & {
        transform: translateY(-20px);
      }
    }
  }


  &.hover-image-zoom {

    .footer-body-padded {
      background: white;
    }

    @include media-breakpoint-up(md) {
      .citi-card:hover & {
        //transform: translateY(-40px);
        .footer-body-padded {
          padding-bottom: 40px;
          margin-top: -20px;
        }

        .card-item-image {
          img {
            transform: translateX(-50%) scale(1.05);
          }
        }
      }
    }
  }
}

