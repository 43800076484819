/*!
 ****************************************************
 ****************************************************
 ****************************************************
 * HEADER.SCSS - START
 ****************************************************
 ****************************************************
 ****************************************************
 */


.navbar {
  position: relative;
  background-color: $black;
  color: $white;
  min-height: 50px;

  z-index: $zIndexNavbar;

  @include media-breakpoint-up(lg) {
    min-height: 80px;
    padding: 0;
  }

  .container {
    position: relative;
    height: 100%;

    // Override container padding inherited from nav
    @include media-breakpoint-between(sm, md) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .5s ease-in-out;
    /* border: 3px solid #fff; */
    height: 36px;
    width: 24px;
    .burger {
      width: 24px;
      height: 2.5px;
      background: #fff;
      //border-radius: 5px;
      box-shadow: 0 2px 5px rgba(255,101,47,.2);
      transition: all .2s ease-in-out;
      &:before, &:after {
        content: '';
        position: absolute;
        width: 24px;
        height: 2.5px;
        background: #fff;
        //border-radius: 5px;
        box-shadow: 0 2px 5px rgba(255,101,47,.2);
        transition: all .2s ease-in-out;
      }

      &:before {
        transform: translateY(-7px);
      }

      &:after {
        transform: translateY(7px);
      }

      &.open {
        transform: translateX(0px);
        background: transparent;
        box-shadow: none;
        &:before {
          transform: rotate(45deg) ;
          top: 0;
        }
        &:after {
          transform: rotate(-45deg) ;
          top: 0;
        }
      }
    }
  }

  .login-btn {
    margin-top: 3px;
    color: $black !important;
    font-size: 14px !important;
    width: 76px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
      color: $white !important;
    }
  }

  li {
    position: relative;
    display: inline;
    margin-right: 20px;

    a {
      font-size: 14px;
    }
  }

  li:last-child {
    margin-right: 0;
  }

  .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 100;
    padding-left: 0 !important;
  }

  .nav-item {
    position: relative;
    cursor: pointer;
    .caret {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      background-image: url("/img/icons/caret-down.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: right center;
    }

    &:hover {
      .nav-link {
        color: $citiblue !important;
      }

      .caret {

        background-image: url("/img/icons/caret-down-blue.svg");
      }
    }
  }

  .navbar-left {
    color: $white;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    align-items: center;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 167px;
    }

    .logo {
      height: 28px;
      top: -2px;
      position: relative;

      @include media-breakpoint-up(md) {
        top: 2px;
      }


      @include media-breakpoint-up(lg) {
        height: 34px;
      }
    }
  }

  .navbar-center {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex: 1 1 auto;
      margin-top: 25px;
      justify-content: space-evenly;
      margin-left: 25px;
      margin-right: 25px;
      -ms-flex: 1 1 auto;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        justify-content: space-around;
      }

      a {
        font-size: 12px;
        color: $white !important;
      }
    }
    @include media-breakpoint-up(xl) {
      margin-left: 50px;
      margin-right: 50px;
      a {
        font-size: 14px;
        color: $white !important;
      }
    }
  }

  .navbar-right {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 25px;
    }

    .hamburger {
      height: 16px;
      @include media-breakpoint-up(lg) {
        height: 17px;
      }
    }

    .user {
      height: 20px;
      @include media-breakpoint-up(lg) {
        height: 25px;
      }
    }
    .search-input {
      background-position: 10px center;
      width: 0;
      top: 0;
      right: -6px;
      transition: all ease-in-out .4s;
      background-color: $black;
      position: absolute;
      overflow: hidden;
      border: 0;
      cursor: pointer;
      padding-top: 10px;
      padding-left: 28px;
      text-indent: 10px;

      &.open {
        transition: all ease-in-out .4s;
        width: 220px;
        position: absolute;
        right: 30px;
        top: 0;
        background-color: $darkGrey;
      }
    }

    .search-container {
      position: absolute;
      right: 1px;
      top: -3px;
      bottom: -5px;

      @include media-breakpoint-up(lg) {
        display: flex;
      }

      .close-icon {
        cursor: pointer;
        margin-left: 10px;
        height: 100%;
        background: black;
        right: 8px;
        img {
          width: 17px;
        }
      }

      &.open {
        transition: all ease-in-out .2s;
        opacity: 100;
        z-index: 2;
        pointer-events: auto;
        display: flex;
        width: 250px;
        right: 8px;
        overflow: unset;
        //right: 8px;

      }
    }

    .close-search {
      padding-top: 1px;
    }
  }

  .link-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    margin-left: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 25px;
      font-size: 17px;

    }
  }
  .title {
    font-weight: 100;
    font-size: 14px;
    color: $white;
  }

  .text-link {
    margin-top: 15px;
  }

  .visible-sm {
    display: none;
    @include media-breakpoint-up(lg) {
      display: inline;
    }
  }

  // Navigation dropdown override
  .dropdown-menu {
    background-color: $black;
    left: -25px;
    padding: 1.5rem 1.5rem 1.5rem 0;
    border-radius: 0;
    flex-direction: row;
    color: $white !important;
    font-size: 14px;;
    font-weight: 100;

    li {
      outline: none;
      box-shadow: none;
      border: none;
    }

    .dropdown-title {
      font-weight: bold;
      padding: 0 1.5rem;
      padding-right: 0;
    }

    .dropdown-item {
      font-family: interstate;
      font-weight: 100;
      padding: 0 1.5rem;
      padding-right: 0;

      a {
        color: $white !important;
      }

      &:hover, &:focus {
        background-color: black;
        color: $citiblue !important;
      }
    }

    .menu-wrapper {
      display: flex;
      flex-direction: row;
    }

    dl {
      margin: 0;
      margin-right: 20px;
      padding: 0;
    }
    dt {
      margin: 0;
      padding: 1px 0;
      text-transform: uppercase;
      font-weight: normal;
    }
    dd {
      margin: 0;
      padding: 1px 0;
    }
  }

  .nav-user {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .nav-hamburger {
    margin-right: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .nav-search {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.login-partial, .app-download-partial {

  &.container {
    position: relative;
  }

  .login-container, .app-download-container {

    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: $zIndexLoginMobile;
    background: $black;
    padding: 16px;
    transform: translateY(100%);
    transition: all ease-in-out .4s;
    visibility: hidden;
    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: $zIndexLoginDesktop;
      left: unset;
      right: 20px;
      width: 240px;
      min-height: 360px;
      transform: translateY(-100%);
      padding: 20px 0 0;
    }

    &.open {
      visibility: visible;
      transform: translateY(0);
      transition: all ease-in-out .4s;
    }

    .login-title {
      text-align: center;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .header-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-up(lg) {
        padding-left: 20px;
        padding-right: 20px;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .bootstrap-select .dropdown-toggle {
          width: auto;
        }
      }

      .language-container {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99999;

        .globe {
          width: 16px;
          height: 16px;
          @include media-breakpoint-up(lg) {
            transform: translateY(-1px);
          }

        }

        .current-language {
          margin-top: 4px;
          margin-left: 8px;
          font-size: 14px;
          cursor: pointer;
          color: $white !important;
          font-weight: 100;

          @include media-breakpoint-up(lg) {
            font-size: 12px;
          }
        }

        .caret {
          margin-left: 5px;
        }
      }
      .close-login-button {
        cursor: pointer;
        img {
          width: 24px;
          @include media-breakpoint-up(lg) {
            width: 17px;
          }
        }
      }

    }
    .form {
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      z-index: 99998;
      color: #FFFFFF;
      background: $black;
      transform: translateY(20%);
      max-width: 325px;
      margin: auto;
      @include media-breakpoint-up(lg) {
        transform: translateY(0);
        padding: 20px;
      }
    }

    label {
      font-size: 12px;
      margin: 0;
    }

    .login-btn {
      margin-top: 29px;
      height: 50px !important;
      line-height: 40px !important;
      font-size: 16px !important;
      @include media-breakpoint-up(lg) {
        font-size: 14px !important;
        height: 40px !important;
        line-height: 27px !important;
        margin-top: 26px;
      }
    }

    .input-wrapper {
      margin-top: 6px;
      margin-bottom: 6px;
      @include media-breakpoint-up(lg) {
        margin-top: 3px;
        margin-bottom: 3px;
      }
    }

    .actions-container {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: 100;

      @include media-breakpoint-up(lg) {
        font-size: 12px;
        margin-top: 15px;
      }

      a {
        color: $white !important;
        //margin-top: 5px;
        margin-bottom: 8px;

        &:hover, &:focus {
          background-color: transparent;
          color: $citiblue !important;
        }

        @include media-breakpoint-up(lg) {
          margin: 0;
          line-height: 1.5em;
        }
      }
    }

    .bootstrap-select {
      color: $white;
      width: unset !important;


      &.show {
        .dropdown-toggle::after {
          transform: scaleY(-1);
        }
      }

      .btn:focus {
        outline: none !important;
      }

      .dropdown-toggle {
        font-family: Interstate, Sans-Serif;
        font-weight: 100;
        font-size: 14px;
        cursor: pointer;
        background: transparent;
        border: 0;
        color: $white !important;
        transform: translateY(2px);
        padding-top: 0;
        padding-bottom: 0;
        text-transform: uppercase;
        outline: none !important;
        letter-spacing: -.5px;
        padding-left: 6px;

        outline: none !important;
        box-shadow: none !important;

        @include media-breakpoint-up(lg) {
          transform: translateY(1px);
        }
        &:after {
          content: "";
          display: inline-block;
          background-image: url("/img/icons/caret-down.svg");
          background-repeat: no-repeat;
          width: 16px;
          height: 16px;
          background-position: right center;
          border: 0;
          background-size: 12px;
        }
      }

      .dropdown-menu {
        background: $black;
        color: $white !important;
        font-size: 14px;
        padding-top: 0;
        margin-top: 0;
        margin-left: -3px;
        min-height: unset !important;
        li, a {
          outline: none;
          box-shadow: none;
          border: none;
        }

        .inner {
          min-height: unset !important;
        }
      }

      .dropdown-item {
        font-family: Interstate, Sans-Serif;
        font-weight: 100;
        text-transform: uppercase;
        padding-left: 10px;
        padding-top: 1px;
        padding-bottom: 1px;
        background: $black;
        letter-spacing: -.5px;
        &.active, &:hover, &:focus {
          color: $citiblue;
          background: transparent !important;
        }

        &.selected {
          display: none;
        }
      }
    }

    .download-info-container {
      color: $white;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 20px 100px;
      max-width: 325px;
      margin: auto;

      .title {
        font-size: 34px;
        font-weight: 100;
        line-height: 44px;
        margin-bottom: 30px;
      }

      .body {
        margin-bottom: 60px;

      }

      .mobile-download-container {
        height: 61px;
        width: 173px;

        a {
          display: inline-block;
          height: 100%;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }

      .learn-more {
        position: absolute;
        bottom: 50px;

      }
    }
  }

  .login-error-msg {
    font-family: Interstate, Sans-Serif;
    color: red;
    text-align: center;
    font-weight: 100;
    font-style: italic;
    margin-bottom: 20px;


    @include media-breakpoint-up(lg) {
      font-size: 12px;
      margin: 0;
      margin-top: -14px;
    }
  }
}

.nav-drawer {
  position: fixed;
  left: 100%;
  color: $white;
  background-color: $black;
  z-index: $zIndexNavDrawer;
  width: 100%;
  height: 100%;
  transition: left ease-in-out .2s;
  overflow-y: scroll;
  padding-top: 50px;
  top: 0;

  &.open {
    left: 0;
    transition: left ease-in-out .2s;
  }

  .nav-menu {
    width: 100%;
    background-color: $black;
    padding: 16px 16px 50px;
  }

  .dropdown {
    border-bottom: 1px solid $white;
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }

  .collapsible-container {
    border-bottom: 1px solid $white;
  }

  .collapsed {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .nav-link {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
  }

  .caret {
    width: 10px;
    margin-right: 5px;
    transform: rotate(180deg);
    top: -1px;
    position: relative;
  }

  .collapsed {

    .caret {
      transform: rotate(0deg);
    }
  }

  .collapse {
    position: relative;
    a {
      color: $white !important;
    }

    dl {
      margin: 0;
    }


    dd, dt, .menu-item {
      margin: 0;
      padding: 20px 0 20px 12px;
      font-weight: normal;
      font-size: 16px;
      border-bottom: 1px solid $grey;
      color: $grey !important;

      a {
        color: $grey !important;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

  }

  .cta-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;


    .btn {
      width: 49%;

      &.login-btn, &.become-a-client-btn {
        padding: 14px 0px !important;
        display: inline-block;
        height: 50px;
        font-size: 16px !important;
      }
    }
  }


  .menu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .caret {
      transform: rotate(270deg);
    }
  }


  .menu-dropdown-container {
    position: relative;
    overflow: hidden;

    &.open {
      overflow: inherit;
    }

    .menu-item {
      &:first-child {
        padding-top: 20px;
      }
    }
  }

  .submenu {

    position: absolute;
    top: 0;
    transform: translateX(100%);
    background: $black;
    width: calc(100% + 3px);
    transition: all .2s ease-in-out;
    z-index: 1;
    padding-left: 1px;
    padding-right: 1px;

    .back-item {
      .caret {
        transform: rotate(90deg);
      }
    }
    .submenu-item {
      margin: 0;
      padding: 20px 0 20px 12px;
      font-weight: normal;
      font-size: 16px;
      border-bottom: 1px solid $grey;
      a {
        color: $grey !important;
      }

      &:last-child {
        border: 0;
      }
    }

    &.open {
      transition: all .2s ease-in-out;
      transform: translateX(-1.5px);
    }
  }
}

/*!
 ****************************************************
 ****************************************************
 ****************************************************
 * HEADER.SCSS - END
 ****************************************************
 ****************************************************
 ****************************************************
 */
