.m28ab-header {

  margin-top: 10px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .container {
    padding: 0;
  }

  .background-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;

    img {
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 75%;
      }
    }
  }

  .text-container {
    width: 75%;
    position: absolute;
    text-align: center;
    padding: 40px 43px 40px;
    background: $citiblue;
    color: $white;
    font-size: 16px;
    font-weight: 100;
    transition: all .2s;

    //@include media-breakpoint-up(md) {
    //  max-width: 600px;
    //  padding: 100px 43px 75px;
    //
    //}


    @include media-breakpoint-up(md) {
      width: 500px;
      padding: 84px 43px 68px;
    }

    @include media-breakpoint-up(lg) {
      width: 600px;

    }

    h1 {
      font-size: 36px;
      line-height: 46px;
      @include media-breakpoint-up(md) {
        font-size: 90px;
        line-height: 95px;
      }

    }
    p {
      margin-top: 10px;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: 22px;
        line-height: 32px;
        margin-top: 24px;
        margin-bottom: 0;

      }
      @include media-breakpoint-up(lg) {
        margin-top: 44px;


      }
    }
  }
}
