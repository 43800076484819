.search-page {
  background: $white;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 40px;

  .breadcrumb-container {
    padding: 0;

    .container {
      padding: 0;
    }
  }

  .search-page-inner-wrapper {
    max-width: 1200px;
    @include media-breakpoint-up(md) {
      padding-top: 120px;
    }
  }

  .search-container {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
    }

    .input-group {
      @include media-breakpoint-up(md) {
        width: auto;
        flex: 1;
      }
    }

    .search-input {
      background: rgb(238, 238, 238);
      background-image: url("/img/icons/search-black.svg");
      background-repeat: no-repeat;
      background-position: 20px center;
      color: $black;
      text-indent: 20px;
      padding-top: 10px;
      @include media-breakpoint-up(md) {
        height: 100%;
        flex: 1;
      }
    }

    ::placeholder {
      font-size: 14px;
      color: $black;
      font-weight: 100;
    }


    .search-button {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        margin-left: 15px;
      }
    }

    .clear-search-btn {
      padding-right: 16px;
    }
  }

  .results-filter-container {

    padding-bottom: 5px;
    font-size: 14px;
    line-height: 30px;

    @include media-breakpoint-up(lg) {
      border: 0;
      padding-bottom: 0;
    }

    a {
      &:hover {
        color: inherit;
      }
    }

    .stats-sorting-options-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      @include media-breakpoint-up(xl) {
        margin: 0;
      }
    }

    .stats {
      color: rgba(0, 0, 0, .7);
      font-weight: 100;

      .value {
        font-weight: normal;
      }
    }

    .filter-container {
      display: flex;
      flex-direction: row;
      @include media-breakpoint-up(md) {
        justify-content: space-between;
      }

    }

    .filter-sort-container {

      font-family: "interstate";
      font-size: 14px;
      border: 1px solid rgba(0, 0, 0, .3);
      padding: 20px;


      @include media-breakpoint-up(xl) {
        border: 0;
        padding: 0;
        padding-top: 5px;
        padding-bottom: 0;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, .3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      h5 {
        font-family: "interstate";
        font-size: 14px;
        padding-right: 3px;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
      }


      .filter-checkboxes-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;

        @include media-breakpoint-up(xl) {
          width: auto;
          flex-wrap: nowrap;

        }

        .checkbox-container {
          width: 50%;

          @include media-breakpoint-up(lg) {
            width: 25%
          }

          @include media-breakpoint-up(xl) {
            width: auto;
            margin-left: 20px;

            &:first-child {
              margin-left: 5px;
            }
          }
        }
      }

      /* Customize the label (the container) */
      .checkbox-container {
        display: inline-block;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: 100;

        @include media-breakpoint-up(xl) {
          width: auto;
          margin: 0px 5px;
        }
      }

      /* Hide the browser's default checkbox */
      .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 7.5px;
        left: 1px;
        height: 12px;
        width: 12px;
        background-color: white;
        border: 1px solid black;
      }

      /* On mouse-over, add a grey background color */
      /*.checkbox-container:hover input ~ .checkmark, */
      .checkbox-container input:focus ~ .checkmark {
        background-color: #ccc !important;
      }

      /* When the checkbox is checked, add a blue background */
      .checkbox-container input:checked ~ .checkmark {
        background-color: black;
        border: 1px solid black;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .checkbox-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .checkbox-container .checkmark:after {
        left: 4px;
        top: 0px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }


    }
  }

  .results {
    margin-bottom: 35px;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }

    .item:last-child {
      //border: 0;
    }
  }

  .item {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    font-weight: 100;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      padding-bottom: 40px;
      padding-top: 40px;
    }

    &:first-child {
      @include media-breakpoint-up(md) {
        padding-top: 20px;
      }
    }

    a {
      color: $citiblue;
    }

    .title-container {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 5px;
      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 5px;
      }
    }

    .date {
      text-transform: uppercase;
    }

    .section {
      text-transform: uppercase;
      font-weight: normal;
      &:after {
        content: "•";
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .date {
      text-transform: uppercase;
      font-weight: 100;
    }

    .section:last-of-type {
      &:after {
        content: "";
      }
    }

    .author {
      text-transform: uppercase;
      &:before {
        content: "•";
        margin-left: 5px;
        margin-right: 5px;
        font-weight: normal;
      }
    }

    .body {
      p {
        margin: 0;
        @include media-breakpoint-up(md) {
          font-weight: normal;
        }
      }
    }

    .details {
      margin-bottom: 5px;
    }
  }

  .page-actions {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .rsp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      margin: 0;
      font-size: 18px;
    }


    .rsp {
      //margin-right: 10px;
      font-weight: 100;
      color: rgba(0,0,0,.7);
    }

    .page-item {

      padding-left: 10px;
      padding-right: 10px;

      a {
        padding: 5px;
        color: $black;
        font-weight: 100;
      }

      &.active {
        a {
          background: $citiblue;

          color: $white;
        }
      }
    }
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    margin: 0;
    padding: 0;
  }


  .sorting-options {
    a {
      font-size: 14px;
      color: rgba(0, 0, 0, .7);
      margin-left: 20px;
      margin-right: 20px;
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;

      &.active {
        color: $citiblue;
        border-bottom: 5px solid $citiblue;

        .chevron {
          path {
            stroke: $citiblue;
          }
        }
      }

      &.desc {
        .chevron svg {
          stroke: rgba(0, 0, 0, .7);
          transform: rotate(180deg);
        }
      }
    }
  }


  .pagination {
    margin-top: 0;
  }

}

.search-result-item-template {
  visibility: hidden;
  display: none;
}


