.m1c_header {
  position: relative;

  &.standard-module {
    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
    }
  }


  .text-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;

    h1 {
      margin: 0;
      font-size: 36px;
      line-height: 50px;
      @include media-breakpoint-up(md) {
        font-size: 46px;
        line-height: 60px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 70px;
        line-height: 80px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 90px;
        line-height: 95px;
      }
    }

    p {
      margin-top: 24px;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: 100;
      @include media-breakpoint-up(md) {
        font-size: 16px;
        line-height: 26px;
        margin-top: 20px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 32px;
        width: 80%;
        margin-top: 30px;

      }
    }

    @include media-breakpoint-up(md) {
      text-align: left;
      justify-content: center;
      margin: 0;
      padding-top: 20px;
    }
  }

  .hero-image-container {
    height: 100%;
    overflow: hidden;
    padding-bottom: 115%;
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    @include media-breakpoint-up(md) {
      padding-bottom: 42%;
      margin: 0;
    }

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';

      left: 0;
      top: 0;
      @include media-breakpoint-up(md) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

  }
}

.breadcrumb-container + .m1c_header {
  @include media-breakpoint-up(md) {
    margin-top: -30px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -40px;
  }
}
