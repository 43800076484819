.cta-partial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;


  @include media-breakpoint-up(lg) {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  p {
    font-family: saintecolombe-italic;
    letter-spacing: -.23px;
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 40px;

    @include media-breakpoint-up(lg) {
      font-size: 26px;
    }
  }
}
