.ma3_post-editorial-links {

  padding-bottom: 20px;

  @include media-breakpoint-up(md) {
    padding-bottom: 130px;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    @include media-breakpoint-up(md) {
      border-bottom: 0;
    }
  }

  .item-wrapper {

    @include media-breakpoint-up(md) {
      &:nth-child(2) {
        border-left: 1px solid rgba(0, 0, 0, .3);;
        border-right: 1px solid rgba(0, 0, 0, .3);
      }
    }

    &:first-child {
      .item {
        padding-top: 0;
        @include media-breakpoint-up(md) {
          padding-top: 50px;
        }
      }
    }

    &:last-child {
      .item {
        border-bottom: 0;
      }
    }
  }


  .text-container {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }

  .title {
    font-family: saintecolombe;
    font-size: 22px;
    line-height: 40px;
    font-weight: 100;
    margin-bottom: 10px;
    width: 100%;
  }

  .body {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
    width: 100%;
  }

  .learn-more {
    margin: 0;
  }
}
