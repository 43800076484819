.m8-offerings {

  .container {

    @include media-breakpoint-up(md) {
      padding-left: 65px;
      padding-right: 65px;
    }
  }
}
