@font-face {
  font-family: 'saintecolombe';
  src: url('../fonts/saintecolombeweb-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saintecolombeweb-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/saintecolombeweb-regular.woff') format('woff'); /* Modern Browsers */
  //url('../fonts/saintecolombeweb-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  //url('../fonts/saintecolombeweb-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saintecolombe';
  src: url('../fonts/saintecolombeweb-bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saintecolombeweb-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/saintecolombeweb-bold.woff') format('woff'); /* Modern Browsers */
  //url('../fonts/saintecolombeweb-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  //url('../fonts/saintecolombeweb-bold.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'saintecolombe';
  src: url('../fonts/saintecolombeweb-light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saintecolombeweb-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/saintecolombeweb-light.woff') format('woff'); /* Modern Browsers */
  //url('../fonts/saintecolombeweb-light.ttf')  format('truetype'), /* Safari, Android, iOS */
  //url('../fonts/saintecolombeweb-light.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'saintecolombe';
  src: url('../fonts/saintecolombeweb-lightitalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saintecolombeweb-lightitalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/saintecolombeweb-lightitalic.woff') format('woff'); /* Modern Browsers */
  //url('../fonts/saintecolombeweb-lightitalic.ttf')  format('truetype'), /* Safari, Android, iOS */
  //url('../fonts/saintecolombeweb-lightitalic.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'saintecolombe';
  src: url('../fonts/saintecolombeweb-italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saintecolombeweb-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/saintecolombeweb-italic.woff') format('woff'); /* Modern Browsers */
  //url('../fonts/saint//ecolombeweb-italic.ttf')  format('truetype'), /* Safari, Android, iOS */
  //url('../fonts/saintecolombeweb-italic.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'saintecolombe';
  src: url('../fonts/saintecolombeweb-bolditalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/saintecolombeweb-bolditalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/saintecolombeweb-bolditalic.woff') format('woff'); /* Modern Browsers */
  //url('../fonts/saintecolombeweb-bolditalic.ttf')  format('truetype'), /* Safari, Android, iOS */
  //url('../fonts/saintecolombeweb-bolditalic.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'interstate';
  src: url('../fonts/Interstate-bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Interstate-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //url('../fonts/Interstate-bold.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Interstate-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Interstate-bold.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: bold;
  font-style: normal;
}



@font-face {
  font-family: 'interstate';
  src: url('../fonts/Interstate-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Interstate-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //url('../fonts/Interstate-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Interstate-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Interstate-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'interstate';
  src: url('../fonts/Interstate-light.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Interstate-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //url('../fonts/Interstate-light.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Interstate-light.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/Interstate-light.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: 100;
  font-style: normal;
}

